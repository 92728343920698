import { setActiveTab } from "app/appSlice";
import { API_URL } from "app/config";
import { selectCurrentToken, selectCurrentUser } from "features/auth/authSlice";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Input, Label, Spinner } from "reactstrap";
import { useAllDropDownDataQuery } from "services/dropdownApiSlice";
import {
  useGetBlockByDistrictQuery,
  useGetDistrictByStateQuery,
  useGetStatesQuery,
} from "services/locationApiSlice";
import swal from "sweetalert";

const KycDetails = ({ saathiDetails, saathiDetailsRefetch }) => {
  const dispatch = useDispatch();
  const user_token = useSelector(selectCurrentToken);
  const currentUser = useSelector(selectCurrentUser);
  const [date, setDate] = useState();
  const [loader, setLoader] = useState(false);
  const inputFields = document.querySelectorAll("input.field");
  const [noOfMember, setnoOfMember] = useState();
  const [aadharNumber, setaadharNumber] = useState();
  const [panNumber, setpanNumber] = useState();
  const [otherDocumentNumber, setotherDocumentNumber] = useState("");

  const aadharNumberhandleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setaadharNumber(value);
  };
  const noOfMemberhandleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setnoOfMember(value);
  };
  const panNumberhandleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setpanNumber(value);
  };
  const otherDocumentNumberhandleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setotherDocumentNumber(value);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  // console.log(JSON.stringify(date) + " date")

  inputFields.forEach((field) => {
    field.addEventListener("input", handleinput);
  });

  // Upload addhar file
  const [aadharselectedFile, setaadharselectedFile] = useState();
  const [panselectedFile, setpanSelectedFile] = useState();
  const [otherselectedFile, setotherSelectedFile] = useState();
  const [profile, setProfile] = useState();
  const [IsSelected, setIsSelected] = useState(false);

  const aadharchangeHandler = (event) => {
    console.log(event.target.files.length + " files");
    setaadharselectedFile(event.target.files[0]);
    setIsSelected(true);
  };
  const panchangeHandler = (event) => {
    setpanSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };
  const profilechangeHandler = (event) => {
    setProfile(event.target.files[0]);
    setIsSelected(true);
  };
  const otherchangeHandler = (event) => {
    setotherSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const [PersonalDetails, editPersonalDetails] = useState({
    nomineeName: "",
    nomineeRelationship: "",
    noOfMember: "",
    dateOfBirth: "",
    gender: "",
    martialStatus: "",
    occupation: "",
    occupation_id: "",
    aadharNumber: "",
    panNumber: "",
    address: "",
    city: "",
    city_id: "",
    state: "",
    state_id: "",
    district: "",
    district_id: "",
    pincode: "",
    aadhar_card: "",
    pan_card: "",
    profile_picture: "",
    other_document: "",
    other_document_type: "",
  });

  useEffect(() => {
    if(saathiDetails?.data?.personal_details){
      editPersonalDetails({...saathiDetails?.data?.personal_details})
    }
  }, [saathiDetails?.data?.personal_details])

  const onInputChange = (event) => {
    editPersonalDetails({
      ...PersonalDetails,
      [event.target.name]: event.target.value,
    });
  };

  function handleinput(e) {
    let inputField = e.target;
    if (inputField.value.length >= 1) {
      let nextField = inputField.nextElementSibling;
      return nextField && nextField.focus();
    }
  }
  var applicaintId = localStorage.getItem("applicant_id");

  var otherdoc = PersonalDetails.OtherDocName === undefined ? "" : OtherDocName;
  var otherDocNo =
    PersonalDetails.otherDocumentNumber === undefined
      ? ""
      : otherDocumentNumber;
  var otherselected = otherselectedFile === undefined ? "" : otherselectedFile;
  const onSubmitSaathiData = (data) => {
    setLoader(true);
    const formData = new FormData();
    
    formData.append(
      "nomineeName",
      PersonalDetails.nomineeName ||
        saathiDetails?.data.personal_details?.nomineeName
    );
    formData.append(
      "nomineeRelationship",
      selectedRelationship ||
        saathiDetails.data?.personal_details?.nomineeRelationship
    );
    formData.append(
      "noOfMember",
      noOfMember || saathiDetails?.data.personal_details?.noOfMember
    );
    formData.append(
      "dateOfBirth",
      date || saathiDetails?.data?.personal_details?.dateOfBirth
    );
    formData.append(
      "gender",
      PersonalDetails.gender || saathiDetails?.data?.personal_details?.gender
    );
    formData.append(
      "martialStatus",
      MaritalStatus || saathiDetails?.data?.personal_details?.martialStatus
    );
    formData.append(
      "occupation",
      selectedOccupation || saathiDetails?.data?.personal_details?.occupation
    );
    formData.append(
      "aadharNumber",
      aadharNumber || saathiDetails?.data.personal_details?.aadharNumber
    );
    formData.append(
      "panNumber",
      PersonalDetails.panNumber ||
        saathiDetails?.data.personal_details?.panNumber
    );
    formData.append(
      "address",
      PersonalDetails.address || saathiDetails?.data.personal_details?.address
    );
    formData.append(
      "city",
      blockName || saathiDetails?.data?.personal_details?.city
    );
    formData.append("city_id", blockId);
    formData.append(
      "state",
      stateName || saathiDetails?.data?.personal_details?.state
    );
    formData.append("state_id", stateId);
    formData.append(
      "district",
      districtName || saathiDetails?.data?.personal_details?.state
    );
    formData.append("district_id", districtId);
    formData.append(
      "pincode",
      PersonalDetails.pincode || saathiDetails?.data?.personal_details?.pincode
    );
    formData.append("ui_section_id", "2");
    //  formData.append("aadhar_card", aadharselectedFile[0])

    // console.log(formData.aadhar_card + " dhehe")
    for (let a = 0; a < data.aadhar_card.length; a++) {
      formData.append("aadhar_card", data.aadhar_card[a] || aadharselectedFile);
    }
    // for (let d = 0; d <= data.profile_picture.length; d++) {
    //   formData.append("profile_picture", data.profile_picture[d]);
    // };
    formData.append("profile_picture", profile);

    for (let d = 0; d < data.pan_card.length; d++) {
      formData.append("pan_card", data.pan_card[d] || panselectedFile);
    }

    // formData.append("pan_card", panselectedFile)
    console.log(panselectedFile + "pan");
    formData.append("other_document", otherselected);
    formData.append("otherDocumentNumber", otherDocNo);
    formData.append("other_document_type", otherdoc);
    formData.append("created_by", currentUser.sub);
    formData.append("created_by_name", currentUser.name);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    // console.log(JSON.stringify(aadharselectedFile) + " Adhar");

    var requestOptions = {
      method: "PUT",
      body: formData,
      headers: { Authorization: `Token ${user_token}` },
    };

    fetch(
      `${API_URL}/empanelment/api/kyc-details/${applicaintId}`,
      requestOptions
    )
      .then((r) => r.json())
      .then((resp) => {
        JSON.stringify(resp);
        const result = resp;

        // console.log(result.message , "resullll")
        if (result.status === 200 || result.status === 201) {
          swal("Success", {
            buttons: false,
            icon: "success",
            title: result.message,
            timer: 1500,
          });
          dispatch(setActiveTab(3));
          setLoader(false);
          saathiDetailsRefetch(localStorage.getItem("applicant_id"));
        } else {
          swal("Error", {
            buttons: false,
            icon: "warning",
            title: result.message,
            timer: 2000,
          });
          setLoader(false);
        }
      });
  };

  const [MaritalStatus, setMaritalStatus] = useState();
  const [Relationship, setRelationship] = useState();
  const [OtherDocName, setOtherDocName] = useState([]);
  const [changeEvent, SetChangeEvent] = useState(null);
  const [isDisabled, setIsDisabled] = useState(null);

  function handleMaritalStatus(MaritalStatus) {
    setMaritalStatus(MaritalStatus);
  }
  function handlOtherDocName(OtherDocName) {
    setOtherDocName(OtherDocName);
    SetChangeEvent(OtherDocName);
    setIsDisabled(OtherDocName);
  }

  // Marital Status selectbox
  const MaritalStatusList = [
    {
      label: "Status",
      options: [
        { value: "single", label: "Unmarried" },
        { value: "married", label: "Married" },
        { value: "divorced", label: "Divorced" },
        { value: "Widow / widower", label: "Widower / widower" },
      ],
    },
  ];

  const DocumenttypeListData = [
    {
      label: "Document",
      options: [
        { value: "", label: "Select-Document" },
        { value: "voter_card", label: "Voter ID" },
        { value: "driving_licence", label: "Driving License" },
        { value: "ration_card", label: "Ration Card" },
        { value: "bank_document", label: "Bank Document" },
      ],
    },
  ];

  const { data: getStates } = useGetStatesQuery();
  const { data: dropdownitem } = useAllDropDownDataQuery();

  const [stateList, setStateList] = useState([]);
  const [stateId, setStateId] = useState("");
  const [stateName, setStateName] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [districtName, setDistrictName] = useState("");
  const [districtId, setDistrictId] = useState(null);
  const [blockList, setBlockList] = useState([]);
  const [blockName, setBlockName] = useState("");
  const [blockId, setBlockId] = useState(null);
  const [Occupation, setOccupation] = useState(null);
  const [selectedOccupation, setSelectedOccupation] = useState(null);
  const [selectedRelationship, setSelectedRelationship] = useState(null);

  const handleOccupationChange = (selectedOption) => {
    setOccupation(selectedOption);
    setSelectedOccupation(selectedOption.value);
  };
  const handleRelationshipChange = (selectedOption) => {
    setRelationship(selectedOption);
    setSelectedRelationship(selectedOption.value);
  };

  //   console.log(selectedRelationship , "&&&")

  useEffect(() => {
    if (getStates !== undefined) {
      setStateList(getStates?.data?.results);
    }
  }, [getStates]);
  const { data: dataDistrict } = useGetDistrictByStateQuery(stateId);
  const { data: dataBlock } = useGetBlockByDistrictQuery(districtId);

  const handleStateOptions = () => {
    let responseAgentArray = [];
    stateList.map((state) => {
      responseAgentArray.push({
        value: state.state_code,
        label: state.state_name,
        ConditionName: "state",
      });
    });
    return responseAgentArray;
  };

  const handleDistrictOptions = () => {
    let responseAgentArray = [];
    districtList.map((district) => {
      responseAgentArray.push({
        value: district.district_code,
        label: district.district_name,
        ConditionName: "district",
      });
    });
    return responseAgentArray;
  };
  const handleBlockOptions = () => {
    let responseAgentArray = [];
    blockList.map((block) => {
      responseAgentArray.push({
        value: block.block_code,
        label: block.block_name,
        ConditionName: "block",
      });
    });
    return responseAgentArray;
  };

  const handleOccupationOptions = () => {
    return dropdownitem?.data?.results[0]?.occupation?.map((item) => ({
      value: item,
      label: item,
    }));
  };
  const handleRelationOptions = () => {
    return dropdownitem?.data?.results[0]?.relations?.map((item) => ({
      value: item,
      label: item,
    }));
  };

  const onLocationChange = (event) => {
    if (event.ConditionName == "state") {
      setBlockId(null);
      setDistrictName("");
      setDistrictId(null);
      setBlockName("");
      setBlockList([]);
      setStateId(event.value);
      setStateName(event.label);
    }
    if (event.ConditionName == "district") {
      setDistrictId(event.value);
      setDistrictName(event.label);
      setBlockId(null);
      setBlockName("");
    }
    if (event.ConditionName == "block") {
      setBlockId(event.value);
      setBlockName(event.label);
    }
  };
  useEffect(() => {
    // refetch()
  }, [stateId, districtId, blockId]);
  useEffect(() => {
    if (dataDistrict != undefined) {
      setDistrictList(dataDistrict?.data?.results);
    }
  }, [dataDistrict]);
  useEffect(() => {
    if (dataBlock != undefined) {
      setBlockList(dataBlock?.data?.results);
    }
  }, [dataBlock]);

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 48,
    }),
  };
  useEffect(() => {}, [saathiDetails]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // console.log("saathiDetails", saathiDetails);

  const isPanCardAvailable = saathiDetails?.data?.all_media?.find(item => item.document_type == "Pan Card");
  const isAadharCardAvailable = saathiDetails?.data?.all_media?.find(item => item.document_type == "Aadhar Card");
  const isProfileAvailable = saathiDetails?.data?.all_media?.find(item => item.document_type == "Profile Picture");
  
  return (
    <section className="col-lg-12">
      <div className="px-4">
        <div className="login_info pl-0">
          <div className="formdetails">
            <form onSubmit={handleSubmit(onSubmitSaathiData)}>
              <div className="row">
                <div className="col-lg-3  mb-2 text_box">
                  <Label className="f_p text_c f_400">
                    Date Of Birth <small className="text-danger">*</small>
                  </Label>

                  <input
                    defaultValue={
                      saathiDetails?.data?.personal_details?.dateOfBirth || ""
                    }
                    className="form-control input_text"
                    placeholder="YYYY-MM-DD"
                    name="dateOfBirth"
                    type="date"
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                  />
                </div>
                {errors.dateOfBirth && (
                  <small className="m input-error text-danger">
                    Please fill DOB
                  </small>
                )}

                <div className="col-lg-3 mb-2">
                  <div className="mb-3">
                    <Label className="f_p text_c f_400">
                      Select Marital Status{" "}
                      <small className="text-danger">*</small>
                    </Label>
                    <Select
                      value={
                        MaritalStatus === undefined
                          ? MaritalStatus
                          : MaritalStatus.value
                      }
                      onChange={(MaritalStatus) => {
                        handleMaritalStatus(MaritalStatus.value);
                      }}
                      defaultValue=""
                      options={MaritalStatusList}
                      styles={customStyles}
                      placeholder={capitalizeFirstLetter(
                        saathiDetails?.data?.personal_details?.martialStatus ||
                          "Select Martial Status"
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-3  mb-2 text_box">
                  <div className="mb-3">
                    <Label className="f_p text_c f_400">
                      Select Occupation <small className="text-danger">*</small>
                    </Label>
                    <Select
                      value={Occupation}
                      onChange={(e) => handleOccupationChange(e)}
                      options={handleOccupationOptions()}
                      styles={customStyles}
                      defaultValue={
                        saathiDetails?.data.personal_details?.address
                      }
                      placeholder={capitalizeFirstLetter(
                        saathiDetails?.data?.personal_details?.occupation ||
                          "Select Occupation"
                      )}
                    />
                  </div>
                </div>

                <div className="col-lg-3  form-check text_box">
                  <Label className="f_p text_c f_400 mx-3 m-0">
                    Gender <small className="text-danger">*</small>
                  </Label>
                  <div className="col-lg-12 p-0 d-flex mx-3">
                    <div className="form-check ps-0 form-check-inline">
                      <input
                        name="gender"
                        id="male"
                        type="radio"
                        value="male"
                        defaultValue="male"
                        onChange={onInputChange}
                        checked={PersonalDetails.gender == "male" }
                      />
                      <Label htmlFor="male" className="ms-2">
                        Male
                      </Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        name="gender"
                        id="female"
                        type="radio"
                        value="female"
                        onChange={onInputChange}
                        checked={PersonalDetails.gender == "female" }
                      />
                      <Label htmlFor="female" className="ms-2">
                        Female
                      </Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        name="gender"
                        id="other"
                        type="radio"
                        value="other"
                        onChange={onInputChange}
                        checked={PersonalDetails.gender == "other" }
                      />
                      <Label htmlFor="other" className="ms-2">
                        other
                      </Label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="mb-1">
                    Residential Details <small className="text-danger">*</small>
                  </h4>
                  <hr />
                </div>
                <div className="col-lg-3  mb-2 text_box">
                  <div className="mb-3">
                    <Label className="f_p text_c f_400">
                      Select State <small className="text-danger">*</small>
                    </Label>

                    <Select
                      options={handleStateOptions()}
                      onChange={onLocationChange}
                      placeholder={capitalizeFirstLetter(
                        saathiDetails?.data?.personal_details?.state ||
                          "Select State"
                      )}
                      // value={stateId === "" ? "Select State" : stateName}
                      styles={customStyles}
                    />
                  </div>
                </div>

                <div className="col-lg-3  mb-2 text_box">
                  <div className="mb-3">
                    <Label className="f_p text_c f_400">
                      Select District<small className="text-danger">*</small>
                    </Label>
                    <Select
                      options={handleDistrictOptions()}
                      onChange={onLocationChange}
                      placeholder={capitalizeFirstLetter(
                        saathiDetails?.data?.personal_details?.district ||
                          "Select State"
                      )}
                      // value={
                      //   districtId === null ? saathiDetails?.data.personal_details?.district : districtName
                      // }
                      styles={customStyles}
                    />
                  </div>
                </div>

                <div className="col-lg-3  mb-2 text_box">
                  <div className="mb-3">
                    <Label className="f_p text_c f_400">
                      Select Block <small className="text-danger">*</small>
                    </Label>
                    <Select
                      options={handleBlockOptions()}
                      placeholder={capitalizeFirstLetter(
                        saathiDetails?.data?.personal_details?.city ||
                          "Select Block"
                      )}
                      value={
                        blockId === null
                          ? "Select block"
                          : { value: blockId, label: blockName }
                      }
                      onChange={onLocationChange}
                      styles={customStyles}
                    />
                  </div>
                </div>

                <div className="col-lg-2  mb-2 text_box">
                  <Label className="f_p text_c f_400">
                    Pin Code <small className="text-danger">*</small>
                  </Label>
                  <input
                    className="form-control "
                    name="pincode"
                    type="text"
                    placeholder="Pincode"
                    required
                    defaultValue={saathiDetails?.data.personal_details?.pincode}
                    onChange={onInputChange}
                    styles={customStyles}
                  />
                  {errors.pincode && (
                    <small className="m input-error text-danger">
                      Enter pin code
                    </small>
                  )}
                </div>

                <div className="col-lg-6  mb-2 text_box">
                  <Label className="f_p text_c f_400">
                    Address <small className="text-danger">*</small>
                  </Label>
                  <input
                    className="form-control input_text"
                    name="address"
                    type="text"
                    placeholder="Enter Address"
                    required
                    onChange={onInputChange}
                    defaultValue={saathiDetails?.data.personal_details?.address}
                  />
                  {errors.address && (
                    <small className="m input-error text-danger">
                      please enter address
                    </small>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-12 ">
                  <h4 className=" m-0">
                    Family Details <small className="text-danger">*</small>
                  </h4>
                  <hr />
                </div>
                <div className=" col-lg-3  mb-2 text_box">
                  <Label className="f_p text_c f_400">
                    No. of Family Member
                    <small className="text-danger">*</small>
                  </Label>

                  <input
                    className="form-control input_text"
                    name="noOfMember"
                    defaultValue={
                      saathiDetails?.data.personal_details?.noOfMember
                    }
                    // value={noOfMember}
                    maxLength="2"
                    onChange={noOfMemberhandleChange}
                    placeholder="Enter Number "
                    required
                  />
                  {errors.noOfMember && (
                    <small className="m input-error text-danger">
                      Enter No. of member
                    </small>
                  )}
                </div>

                <div className=" col-lg-3  mb-2 text_box">
                  <Label className="f_p text_c f_400">
                    Nominee <small className="text-danger">*</small>
                  </Label>
                  <input
                    className="form-control input_text"
                    name="nomineeName"
                    type="text"
                    required
                    placeholder="Enter Nominee Name"
                    onChange={onInputChange}
                    defaultValue={
                      saathiDetails?.data.personal_details?.nomineeName
                    }
                  />
                  {errors.nomineeName && (
                    <small className="m input-error text-danger">
                      {" "}
                      Enter name of nominee{" "}
                      <small className="text-danger">*</small>
                    </small>
                  )}
                </div>

                <div className="col-lg-3  mb-2 text_box">
                  <div className="mb-3">
                    <Label className="f_p text_c f_400">
                      Select Relationship of Nominee{" "}
                      <small className="text-danger">*</small>
                    </Label>
                    <Select
                      value={Relationship}
                      onChange={(e) => handleRelationshipChange(e)}
                      options={handleRelationOptions()}
                      styles={customStyles}
                      placeholder={capitalizeFirstLetter(
                        saathiDetails?.data?.personal_details
                          ?.nomineeRelationship || "Select Nominee Relationship"
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-lg-12">
                  <h4 className="">Document Details</h4>
                  <hr />
                </div>
                <div className=" col-lg-3  mb-2 text_box ">
                  <Label className="f_p text_c f_400">
                    Aadhar Number <small className="text-danger">*</small>
                  </Label>
                  <input
                    className="form-control input_text"
                    name="aadharNumber"
                    placeholder="Enter Aadhar Number"
                    defaultValue={
                      saathiDetails?.data?.personal_details?.aadharNumber
                    }
                    // value={aadharNumber}
                    maxLength="12"
                    minLength="12"
                    onChange={aadharNumberhandleChange}
                    required
                  />
                </div>

                <div className=" col-lg-3  mb-2 text_box">
                  <Label className="f_p text_c f_400">PAN Number</Label>
                  <input
                    name="panNumber"
                    type="text"
                    className="form-control input_text"
                    placeholder="Enter PAN Number"
                    defaultValue={
                      saathiDetails?.data.personal_details?.panNumber
                    }
                    maxLength="10"
                    minLength="10"
                    onChange={onInputChange}
                    required
                  />
                </div>

                <div className="col-lg-3  mb-2 mb-4">
                  <Label className="f_p text_c f_400">
                    Aadhar Card <small className="text-danger">*</small>
                    <small>(Image Or Pdf Format Only*)</small>
                  </Label>
                  <input
                    type="file"
                    className="form-control input_text"
                    name="aadhar_card"
                    id="AddharImg"
                    multiple
                    accept="image/jpg,image/jpeg,image/png,application/pdf"
                    filename="setaadharselectedFile"
                    // onChange={aadharchangeHandler}
                    {...register("aadhar_card", {})}
                    required={isAadharCardAvailable?.filename !== "" ? false : true}
                  />
                </div>

                <div className="col-lg-3  mb-2 mb-4">
                  <Label className="f_p text_c f_400">
                    Pan Card <small>(Image Or Pdf Format Only*)</small>
                  </Label>
                  <input
                    type="file"
                    className="form-control"
                    name="pan_card"
                    accept=" image/jpeg,image/png,application/pdf"
                    filename="panselectedFile"
                    // onChange={panchangeHandler}
                    {...register("pan_card", {})}
                    required={isPanCardAvailable?.filename !== "" ? false : true}
                  />
                  {errors.pan_card && (
                    <small className="m input-error text-danger">
                      Choose front and back side of pan
                    </small>
                  )}
                </div>

                <div className="col-lg-3  mb-2 mb-4">
                  <Label className="f_p text_c f_400">
                    Upload Profile picture <small>(Image format only*)</small>
                  </Label>
                  <input
                    type="file"
                    className="form-control input_text"
                    name="profile_picture"
                    accept="image/jpeg, image/png,image/jpg"
                    filename="panselectedFile"
                    onChange={profilechangeHandler}
                    required={isProfileAvailable?.filename !== "" ? false : true}


                  />
                  {errors.profile_picture && (
                    <small className="m input-error text-danger">
                      Choose profile picture
                    </small>
                  )}
                </div>

                {/**** other Document */}

                <div className="col-lg-3  mb-2 text_box">
                  <div className="mb-3">
                    <Label className="f_p text_c f_400">
                      Select Other Document
                    </Label>
                    <Select
                      value={
                        OtherDocName === undefined
                          ? OtherDocName
                          : OtherDocName.label
                      }
                      onChange={(OtherDocName) => {
                        handlOtherDocName(OtherDocName.value);
                        SetChangeEvent(OtherDocName);
                      }}
                      options={DocumenttypeListData}
                      classNamePrefix="select2-selection"
                      required={panNumber == "" ? true : false}
                    />
                  </div>
                </div>

                <div className=" col-lg-3  mb-2 text_box">
                  <Label className="f_p text_c f_400">
                    Other Document Number
                  </Label>
                  <input
                    className="form-control input_text"
                    name="otherDocumentNumber"
                    placeholder="Enter Document Number"
                    defaultValue={
                      saathiDetails?.data?.personal_details?.otherDocumentNumber
                    }
                    type="text"
                    maxLength="18"
                    minLength=""
                    onChange={onInputChange}
                    disabled={changeEvent == null ? true : false}
                    required={panNumber == "" ? true : false}
                  />
                </div>

                <div className="col-lg-3  mb-2 mb-4">
                  <Label className="f_p text_c f_400">
                    Document <small>(Image Or Pdf Format Only*)</small>
                  </Label>
                  <input
                    type="file"
                    className="form-control input_text"
                    id="OtherDocImg"
                    name="other_document"
                    multiple
                    accept="image/jpeg,image/png,application/pdf"
                    filename="otherselectedFile"
                    onChange={otherchangeHandler}
                    disabled={changeEvent == null ? true : false}
                    required={panNumber == "" ? true : false}
                  />
                </div>
              </div>

              <div className="col-lg-12 text-center m-auto pb-5 mt-5">
                <button type="submit" className="btn btn-success p-3">
                  <span class="mdi mdi-content-save "></span>
                  {saathiDetails?.data?.personal_details?.aadharNumber === null 
                    ? " Submit"
                    : " Update"}{" "}
                  KYC Details &nbsp;
                  {loader ? (
                    <Spinner animation="border" size="sm"></Spinner>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default KycDetails;
