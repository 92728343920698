import { apiSlice } from "app/api/apiSlice"

export const locationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStates: builder.query({
            query: () => `/api/v1/master/states`
        }),
       getDistrictByState: builder.query({
            query: (stateId) => `/api/v1/master/districts?state_code=${stateId}`
        }),
        getLevelByState: builder.query({
            query: (selectlevel) => `/api/v1/master/${selectlevel.selectlevel}?state_code=${selectlevel.Statecode}`
        }),
        getBlockByDistrict: builder.query({
            query: (districtId) => `/api/v1/master/blocks?district_code=${districtId}`
        }),
        getSubDistrict:builder.query({
            query: (stateId,districtId) => `/api/v1/master/sub_districts?state_code=${stateId}&district_code=${districtId}`

        }),
        getBlockBysubDstrict:builder.query({
            query: (subDistictId) => `/api/v1/master/blocks?sub_district_code=${subDistictId}`

        }),
        getVillageByBlock: builder.query({
            query: (blockId) => `/api/v1/master/villages?block_code=${blockId}`
        }),
       
        
        // getDistrictByState: builder.query({
        //     query: (stateId) => `/api/v1/master/districts?state_code=${stateId}&q=${stateId.searchKey}` ? stateId.searchKey : `/api/v1/master/districts?state_code=${stateId}`
        // }),
       
       

})
})

export const { useGetStatesQuery,useGetDistrictByStateQuery,useGetBlockByDistrictQuery,useGetVillageByBlockQuery ,useGetSubDistrictQuery ,useGetBlockBysubDstrictQuery,useGetLevelByStateQuery} = locationApiSlice 