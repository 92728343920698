import React from "react"

const TableLoader = () => {

  return (
    <table className="loader">
      <tbody>
        <tr>
          <td className="td-5"><span></span></td>
          <td className="td-4"></td>
          <td className="td-3"><span></span></td>
          <td className="td-4"></td>
          <td className="td-5"><span></span></td>
        </tr>
        <tr>
          <td className="td-5"><span></span></td>
          <td className="td-4"></td>
          <td className="td-3"><span></span></td>
          <td className="td-4"></td>
          <td className="td-5"><span></span></td>
        </tr>

      </tbody>
    </table>
  )
}



export default TableLoader
