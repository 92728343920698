import React from "react";
import { useState } from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";

const Trading = () => {
    const [selected,setSelected]=useState("")
  const designation = [
    { value: "Buyer", label: "Buyer" },
    { value: "Seller", label: "Seller" },
    { value: "Both", label: "Both" },
  ];

  const handleChangeField =(event)=>{
    setSelected(event)
  }
//   console.log(selected, "selected")
  return (
    <div>
      <div className="d-flex">
        <h2 className="">Trading Page</h2>
        <Col lg="3" className="mx-3">
          <Select options={designation} onChange={(e)=>handleChangeField(e.value)}/>
        </Col>
      </div>
     {selected === "Buyer" ?  <div><h4>Buyer</h4></div> : ""}
     {selected === "Seller" ?<div><h4>Seller</h4></div> : ""}
      {selected === "Both" ? <div><h4>Both</h4></div> : ""}

    </div>
  );
};

export default Trading;
