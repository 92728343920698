import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from 'app/config'
import { setCredentials, logOut } from 'features/auth/authSlice'

const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken = getState().auth.accessToken
            headers.set("authorization", `Bearer ${accessToken}`)
        return headers 

    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result?.meta?.response?.status === 401) {
        
        // const state = api.getState()
        // // send refresh token to get new access token 
        // const refreshResult = await baseQuery({url: '/api/auth/refresh', method: 'POST',headers: { authorization: `Bearer ${state.auth.refreshToken}`}}, api, extraOptions)

        // if (refreshResult?.data) {
        //     refreshResult.data.data.refresh_token = state.auth.refreshToken
        //     api.dispatch(setCredentials({ ...refreshResult.data }))

        //     // retry the original query with new access token 
        //     result = await baseQuery(args, api, extraOptions)

        // } else { 
        //     api.dispatch(logOut())
        // }
    } else if (result.error){
        // api.dispatch(logOut())
        
        // alert(result?.data?.message)
        
    }

    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({}),
    keepUnusedDataFor: 1,
})