
export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('reduxState')
        if (serializedState == null) {
            return undefined
        }
        return JSON.parse(serializedState)
    } 
    catch (error){
        return undefined
    }
}

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('reduxState', serializedState)
    } 
    catch (error){
        // Ignore
    }
}