import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "./api/apiSlice"
import authReducer from 'features/auth/authSlice'
import appReducer from 'app/appSlice'
import { loadState, saveState } from "data/localstorage"
import cardReducer from "features/Procurement/common/createCardSlice"
const persistedState = loadState()

export const store = configureStore({

    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        app: appReducer,
        card: cardReducer 
    },
    preloadedState: persistedState,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
       devTools: process.env.NODE_ENV !== 'production'
})

store.subscribe(()=>{
    saveState(store.getState())
})