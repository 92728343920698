import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "data/localstorage";
import jwt_decode from "jwt-decode";

const initialState = {accessToken: null, refreshToken: null, currentUser: null}
const prevState = loadState()

const authSlice = createSlice({
    name: "auth",
    initialState: {...initialState, ...prevState?.auth},
    reducers: {
        setCredentials: (state, action) => {
            const tokenData = action.payload
            const decoded = jwt_decode(tokenData.data.access_token)
            state.currentUser = decoded
            state.language = decoded?.locale || "en"
            state.accessToken = tokenData.data.access_token
            state.refreshToken = tokenData.data?.refresh_token
        },
        setLanguage: (state, action) => {
            state.language = action.payload
        },
        logOut: (state, action) => {
            state.accessToken = null
            state.refreshToken = null
        }
    }
})

export const { setCredentials, logOut, setLanguage } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.currentUser
export const selectLanguage = (state) => state.auth.language
export const selectCurrentToken = (state) => state.auth.accessToken
export const selectCurrentRoles = (state) => state.auth.currentUser