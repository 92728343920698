import React, { useEffect } from "react";
import { useState } from "react";
import { Card, CardBody, Col, Input, Row } from "reactstrap";
import TableLoader from "shared/TableLoader";
import { Button, Modal, Spinner } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import swal from "sweetalert";

import CustomSearchBar from "components/CustomSearchBar";
import CommissionApproval from "./CommissionApproval";
import creditImg from "../../../assets/images/credit-card.png";
import total_img from "../../../assets/images/total_img.png";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  useAddSaathiCommissionMutation,
  useGetAgentDetailsQuery,
  useGetAgentListQuery,
  useGetAgentsIdQuery,
  useGetProductListQuery,
} from "./CommissionApiSlice";
import CreateManualCommission from "./CreateManualCommission";
import { useGetCommissionApplicationListbyIdQuery } from "features/banking/BankingApiSlice";

const AssingCommission = () => {
  const [loading, setloading] = useState(true);
  const [saathiData, setSaathiData] = useState();
  const [showLoaderTable, setLoaderTable] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [rowsPerPage, setRowsperPage] = useState(10);
  const [commission, setCommission] = useState("");
  const [commissionModal, setCommissionModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedViewDetails, setSelectedViewDetail] = useState([]);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [commissionCreationModal, setCommissionCrationModal] = useState(false);
  const [viewCommissioDetailModal, setViewCommssionDetailModal] = useState(
    false
  );

  const [pagination, setPagination] = useState({ page: 1, pageCount: 0 });

  const { data: data, isLoading, refetch } = useGetAgentListQuery({
    searchKey,
    pagination,
  });

  const { data: agents, refetch: agentListRefetch } = useGetAgentsIdQuery(
    JSON.stringify(selectedUsers)
  );
  const {
    data: applicationList,
    refetch: applicationListRefetch,
    isLoading: applicationListLoaing,
    isError: applicationListError,
    isFetching: applicationlistFetching,
  } = useGetCommissionApplicationListbyIdQuery(selectedAgent, {
    skip: selectedAgent == "",
    refetchOnMountOrArgChange: true,
  });



  const {
    data: AgentDetails,
    isError: AgentDetailsError,
    isLoading: AgentDetailLoading,
    isFetching: AgentDetailsFetching,
  } = useGetAgentDetailsQuery(selectedViewDetails);

  const AgentProductDetails =
    AgentDetails?.data?.product_commission_details || null;

  useEffect(() => {
    const dataFromApi = data?.data ? data?.data?.results : [];
    setAgentList(dataFromApi);
  }, [agentList]);

  useEffect(() => {
    setPagination({ ...pagination, limit: rowsPerPage });
    setLoaderTable(false);
  }, [rowsPerPage]);

  const {
    data: productData,
    isLoading: productDataLoading,
  } = useGetProductListQuery();

  useEffect(() => {
    const dataFromApi = productData?.data ? productData?.data : [];
    setApiData(dataFromApi);
  }, [productData]);

  const [AddCommission] = useAddSaathiCommissionMutation();

  useEffect(() => {
    const dataFromApi = data?.data.results ? data?.data.results : [];
    const paginationFromApi = data?.data?.pages
      ? { page: data?.data?.current_page, pageCount: data?.data?.pages }
      : pagination;
    setSaathiData(dataFromApi);
    setPagination(paginationFromApi);
    setloading(false);
  }, [data]);

  const handleAction = (item) => {
    setViewDetailsModal(true);
    setSelectedViewDetail(item?.row?.employee_code);
  };

  const columns = [
    {
      field: "profile_pic",
      headerName: "Profile ",
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <div>
              <img
                src={params.value}
                width="30px"
                height="30px"
                alt=""
                className="rounded-circle"
              />
            </div>
          </>
        );
      },
    },
    { field: "employee_code", headerName: "POSP ID", width: 280 },
    {
      field: "full_name",
      headerName: "Full Name",
      width: 360,
    },
    { field: "phone_number", headerName: "Phone No", width: 220 },
    {
      field: "View",
      headerName: "Actions",
      width: 210,
      renderCell: (cellvalues) => {
        return (
          <div className="d-flex">
            <Tooltip title="View Product Details">
              <i
                className="mdi mdi-eye text-warning fs-4   cursor"
                name={"view"}
                onClick={() => handleAction(cellvalues)}
              />
            </Tooltip>

            <Tooltip title="View Commission Details">
              <i
                className="mdi mdi-file-find text-success commision_btn fs-5  mx-4  cursor "
                name={"view"}
                onClick={() => handleViewCommision(cellvalues)}
              />
            </Tooltip>
            <Tooltip title="View Application Details">
              <i
                className="mdi mdi-database-eye text-orange fs-5 mt-1   cursor "
                name={"view"}
                onClick={() => setCommissionCrationModal(true)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const CustomPagination = () => {
    return (
      <Pagination
        color="primary"
        className="my-2 mx-2"
        count={pagination?.pageCount}
        page={pagination?.page}
        onChange={(event, value) => {
          setPagination({ page: value, count: value });
          setSelectedUsers(selectedUsers);
        }}
        variant="outlined"
        shape="rounded"
      />
    );
  };

  const filterDataBasedId = (item) => {
    const selectedRows = item.map((selectedRowIndex) =>
      saathiData.find((row) => row.applicant_id === selectedRowIndex)
    );
    let pospIdList = [];
    selectedRows?.map((item) => {
      if (item?.posp_id) {
        pospIdList.push(item?.posp_id);
      }
    });
    setSelectedUsers(item);
  };

  const handleSelectProduct = (item) => {
    const selectedIndex = selectedItems.indexOf(item.product_code);
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, item.product_code]);
    } else {
      const newSelectedItems = [...selectedItems];
      newSelectedItems.splice(selectedIndex, 1);
      setSelectedItems(newSelectedItems);
    }
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      const allProductCodes = apiData.map((item) => item.product_code);
      setSelectedItems(allProductCodes);
    } else {
      setSelectedItems([]);
    }
  };

  const handleChange = (e) => {
    setCommission(e.target.value);
  };

  const handleAddCommission = (e) => {
    setloading(true);
    e.preventDefault();
    const sendData = {
      posp_ids: selectedUsers,
      product_codes: selectedItems,
      commission_percent: commission,
      applicable_date: selectedDate,
    };

    AddCommission(sendData)
      .unwrap()
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          swal("Data Updated", {
            buttons: false,
            icon: "success",
            title: "Commission Updated Successfully",
            timer: 1500,
          });
          agentListRefetch();
          refetch();
          setloading(false);
          setCommissionModal(false);
        } else {
          swal("Error", {
            buttons: false,
            icon: "warning",
            title: data.message,
            timer: 1500,
          });
          setloading(false);
        }
      })
      .catch((e) => {
        setloading(false);
        swal(e?.data?.error?.posp_ids[0], {
          buttons: false,
          icon: "warning",
          title: "Error",
          timer: 3000,
        });
      });
  };

  const handleDetailsModal = () => {
    setViewDetailsModal(false);
    setSelectedViewDetail("");
  };
  const handleChangeSearch = (message) => {
    setSearchKey(message);
    refetch();
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const today = new Date().toISOString().split("T")[0];

  const handleViewCommision = (item) => {
    setViewCommssionDetailModal(true);
  };

  const handleCommissionModal = () => {
    setViewCommssionDetailModal(false);
  };

  const handleSetCommission = () => {
    if (selectedUsers.length > 0) {
      setCommissionModal(true);
    } else {
      toast.warn("Select Agent to Set Commission");
    }
  };

  return (
    <React.Fragment>
      <div className="position-relative">
        <Row className="mt-1">
          <Col className="col-12">
            <Card className="saathi_table">
              <CardBody
                className="saathi_table position-relative"
                style={{ padding: "1rem" }}
              >
                {saathiData == [] || saathiData == undefined ? (
                  <TableLoader />
                ) : (
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="col-md-5 d-flex justify-content-start">
                        <CustomSearchBar
                          sendSearchFunction={handleChangeSearch}
                        />
                      </div>
                      <Col lg="4" className=" mb-2 text-end ">
                        <Button
                          size="sm"
                          className="btn waves-effect "
                          style={{ padding: "9px" }}
                          onClick={() => handleSetCommission()}
                        >
                          <CurrencyRupeeIcon className="fs-6" />
                          Set Commission
                        </Button>
                        {/* {commissionError ? (
                          <p className="m-0 f300 text-danger">
                            {" "}
                            Please Select Agent to set Commission
                          </p>
                        ) : (
                          ""
                        )} */}
                      </Col>
                    </div>

                    <Box sx={{ width: "100%", height: "85vh", zIndex: "0" }}>
                      <DataGrid
                        disableRowSelectionOnClick
                        rows={saathiData}
                        columns={columns}
                        loading={isLoading}
                        checkboxSelection
                        getRowId={(row) => row?.employee_code}
                        onRowClick={(params) => (
                          setSelectedUsers([...[params.id]]),
                          setSelectedAgent([...[params.id]])
                        )}
                        onRowSelectionModelChange={(item) => {
                          filterDataBasedId(item);
                        }}
                        components={{
                          Pagination: CustomPagination,
                        }}
                      />
                    </Box>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          show={commissionModal}
          onHide={() => setCommissionModal(false)}
          size="xl"
        >
          <Modal.Header closeButton style={{ backgroundColor: "lightgrey" }}>
            <Modal.Title className="fw-bold">POSP Commission</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-2 ">
            <h5 className="mx-4 mb-1 ">Selected Saathi</h5>
            <div className="row mx-2">
              {agents?.data?.map((item, index) => {
                return (
                  <div className="col-lg-3   " key={index}>
                    <Card className="userCard position-relative">
                      <CardBody className="p-3 center_class ">
                        <AccountCircleIcon className="indexStyle2 user_badge_icon" />

                        <div className="d-flex text-center">
                          <h5 className="mx-2 text-success">{item.posp_id}</h5>
                        </div>
                        <div className="d-flex">
                          <h6 className="mx-2 text-success">
                            {item?.full_name.charAt(0).toUpperCase() +
                              item?.full_name.slice(1).toLowerCase()}
                          </h6>
                        </div>
                        <div className="d-flex">
                          <h6>Commission % -</h6>
                          <h6 className="mx-2 text-success">
                            {item?.commission_percent}
                          </h6>
                        </div>
                      </CardBody>
                    </Card>

                    {/* <Card className="userCard">
                      <CardBody className="p-0  d-flex">
                        <div className="col-lg-4">
                          <img
                            src={percentage_img}
                            className="img-fluid"
                            style={{
                              height: "85px",
                              width: "100%",
                              borderRadius: "5px 0px 0px 5px",
                            }}
                          />
                         
                          
                        </div>
                        <div className="col-lg-8 p-2">
                          <div className="d-flex text-center">
                            <h5 className="mx-2 text-success">
                              {item.posp_id}
                            </h5>
                          </div>
                          <div className="d-flex">
                            <h6 className="mx-2 text-success">
                              {item?.full_name.charAt(0).toUpperCase() +
                                item?.full_name.slice(1).toLowerCase()}
                            </h6>
                          </div>
                          <div className="d-flex">
                            <h6 className="mx-2">Commission % -</h6>
                            <h6 className="mx-2 text-success">
                              {item?.commission_percent}
                            </h6>
                          </div>
                        </div>
                      </CardBody>
                    </Card> */}
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex mx-4">
                <h5 className="mx-2 mt-1  m-0">Select All</h5>
                <Input
                  type="checkbox"
                  readOnly
                  onChange={handleSelectAll}
                  checked={selectAll}
                  className="mt-2"
                />
              </div>
              {selectedItems.length < 1 ? (
                <div className="d-flex mx-4 mt-1">
                  <h5 className="m-0">Total Products - </h5>
                  <h5 className="m-0 text-success">&nbsp; {apiData.length}</h5>
                </div>
              ) : (
                <div className="d-flex mx-4 mt-1">
                  <h5 className="m-0">Selected Products - </h5>
                  <h5 className="m-0 text-success">
                    &nbsp; {selectedItems.length}
                  </h5>
                </div>
              )}
            </div>
            <div className="row data_card mx-3">
              {apiData?.length > 0 ? (
                <div
                  className="row mt-2 mb-2"
                  style={{
                    height: "32vh",
                    overflowY: "scroll",
                    scrollbarColor: "red",
                  }}
                >
                  {apiData?.map((item, index) => {
                    const isSelected = selectedItems.includes(
                      item.product_code
                    );
                    return (
                      <div
                        className="col-lg-3 mt-2  mb-4"
                        onClick={() => handleSelectProduct(item)}
                        key={index}
                      >
                        <Card
                          className={`commission_card d-flex  ${
                            isSelected ? "clickedCard" : ""
                          }`}
                          style={{
                            backgroundColor: "rgb(233 135 34 / 2%)",
                          }}
                        >
                          <CardBody className="card_font p-3 position-relative">
                            <div
                              className="position-absolute"
                              style={{ right: "8px", top: "4px" }}
                            >
                              <input
                                type="checkbox"
                                checked={isSelected}
                                readOnly
                              />
                            </div>

                            <div className="d-flex">
                              <h6>Product </h6>
                              <h6 className="mx-3 text-orange">
                                {item.product_name}
                              </h6>
                            </div>
                            <div className="d-flex">
                              <h6>Category </h6>
                              <h6 className="mx-3 text-orange">
                                {item.product_category}
                              </h6>
                            </div>
                            <div className="d-flex">
                              <h6>Commission % </h6>
                              <h6 className="mx-3 text-orange">
                                {item.commission_percent}
                              </h6>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-center mt-3">
                  {productDataLoading ? (
                    <Spinner color="primary" />
                  ) : (
                    <h5>No data available</h5>
                  )}
                </div>
              )}
            </div>{" "}
            <form onSubmit={(e) => handleAddCommission(e)}>
              <div className="mx-4 mt-3">
                <div className="d-flex gap-3 align-items-">
                  <Col lg="4" className="mb-3">
                    <label className="text-black mb-2">
                      Saathi Commission %
                    </label>
                    <Input
                      type="text"
                      name="commission_percent"
                      placeholder="Enter value"
                      onChange={(e) => handleChange(e)}
                      className="rounded input_text2"
                    />
                  </Col>
                  <Col lg="2">
                    <label className="text-black mb-2">Applicable Date</label>
                    <Input
                      type="date"
                      className="rounded input_text2 "
                      min={today}
                      value={selectedDate}
                      onChange={handleDateChange}
                      required
                    />
                    {selectedDate && <p>Selected Date: {selectedDate}</p>}
                  </Col>
                </div>

                <div className="text-center mb-4">
                  <button
                    className="btn bg-success px-5  text-white"
                    type="submit"
                  >
                    {" "}
                    Submit &nbsp;
                    {loading ? (
                      <Spinner animation="border" size="sm"></Spinner>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal show={viewDetailsModal} onHide={handleDetailsModal} size="xl">
          <Modal.Header closeButton style={{ backgroundColor: "lightgrey" }}>
            <Modal.Title className="fw-bold">
              POSP Products Commission
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-2 ">
            <div className="d-flex flex-lg-row flex-column">
              <div className=" mx-2 col-lg-3 col-12 ">
                <h5 className="mx-3 mb-2 ">Selected Saathi</h5>
                <div className="col-lg-12   ">
                  {AgentDetailsError ? (
                    <Card className="userCard position-relative ">
                      <CardBody className="p-3 center_class">
                        <h5 className="m-0">
                          "No Data Avaiable for Selected Saathi"
                        </h5>
                      </CardBody>
                    </Card>
                  ) : AgentDetailLoading || AgentDetailsFetching ? (
                    <div>
                      <div className="wrapper2">
                        <div className="card-loader card-loader--tabs"></div>
                      </div>
                    </div>
                  ) : (
                    <div className=" d-flex mx-2  align-items-center">
                      <div className="card">
                        <div className="upper">
                          <img
                            src="https://i.imgur.com/Qtrsrk5.jpg"
                            className="img-fluid"
                          />
                        </div>

                        <div className="user text-center">
                          <div className="profile">
                            <img
                              src="https://imgs.search.brave.com/urF8FbrZXLObJsFKVlzVgKROpbHRvjT24j03KO9nOZI/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by91/c2VyLWZyb250LXNp/ZGUtd2l0aC13aGl0/ZS1iYWNrZ3JvdW5k/XzE4NzI5OS00MDAw/Ny5qcGc_c2l6ZT02/MjYmZXh0PWpwZw"
                              className="rounded-circle"
                              width="50"
                            />
                          </div>
                        </div>

                        <div className=" text-center ">
                          <h4 className="mb-0 text-success">
                            {AgentDetails?.data?.full_name
                              ?.charAt(0)
                              ?.toUpperCase() +
                              AgentDetails?.data?.full_name
                                ?.slice(1)
                                ?.toLowerCase()}
                          </h4>
                          <h5 className=" d-block mb-1 text-success">
                            {" "}
                            {AgentDetails?.data?.posp_id}
                          </h5>

                          <div className="d-flex justify-content-center align-items-center px-4 ">
                            <div className="d-flex mb-2 text-success">
                              <h5 className="mb-0">Commission % </h5>
                              <h5 className="mx-1">
                                {AgentDetails?.data?.commission_percent}
                              </h5>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center align-items-center ">
                            <div className="col-lg-10">
                              <hr className="m-0"></hr>
                            </div>
                          </div>

                          <div className="d-flex flex-column px-4 mt-2">
                            <div className="d-flex justify-content-between mb-2">
                              <h6 className="mb-0">
                                Total Commission
                                <img
                                  src={total_img}
                                  className="img-fluid mx-1"
                                  style={{ height: "16px", width: "16px" }}
                                  alt="Total Commission Icon"
                                />
                              </h6>
                              <h6 className="mb-0">
                                <CurrencyRupeeIcon
                                  className="fs-6 "
                                  style={{ marginTop: "-4%" }}
                                />{" "}
                                {AgentDetails?.data?.total_commissions}
                              </h6>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <h6 className="mb-0">
                                Total Credited
                                <img
                                  src={creditImg}
                                  className="img-fluid mx-1"
                                  style={{ height: "16px", width: "16px" }}
                                  alt="Total Credited Icon"
                                />
                              </h6>
                              <h6 className="mb-0">
                                <CurrencyRupeeIcon
                                  className="fs-6"
                                  style={{ marginTop: "-4%" }}
                                />{" "}
                                {AgentDetails?.data?.total_credited}
                              </h6>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <h6 className="mb-0">
                                Total Pending
                                <img
                                  src={creditImg}
                                  className="img-fluid mx-1"
                                  style={{ height: "16px", width: "16px" }}
                                  alt="Total Pending Icon"
                                />
                              </h6>
                              <h6 className="mb-0">
                                {" "}
                                <CurrencyRupeeIcon
                                  className="fs-6 "
                                  style={{ marginTop: "-4%" }}
                                />
                                {AgentDetails?.data?.total_pending}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className=" mb-3 col-lg-9 ">
                <h5 className="mb-1 ">Products Commission</h5>
                <div className="row col-lg-12">
                  {AgentProductDetails ? (
                    AgentProductDetails.length > 0 ? (
                      AgentProductDetails.map((item, index) => {
                        return (
                          <div className="col-lg-4 col-6 mt-2" key={index}>
                            <div
                              className="card voutchers"
                              style={{ marginBottom: "18px" }}
                            >
                              <div className="voutcher-divider">
                                <div className="voutcher-left text-center p-2">
                                  <span className="h6 text-primary">
                                    {item.product_name}
                                  </span>
                                  <div className="fs-300">
                                    <span className="h6">Commission % </span> :{" "}
                                    <span className="h6">
                                      {item.commission_percent}
                                    </span>
                                  </div>
                                  <div className="fs-300">
                                    <span className="h6 badge-icon">
                                      Active Date{" "}
                                    </span>{" "}
                                    :{" "}
                                    <span className="h6 badge-icon">
                                      {item.applicable_date.split(" ")[0]}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="">
                        <Card className="col-lg-4 mt-1">
                          <CardBody className="p-4 h5 m-0">
                            No Product data available
                          </CardBody>
                        </Card>
                      </div>
                    )
                  ) : AgentDetailLoading || AgentDetailsFetching ? (
                    <div>
                      <h5 className="mt-2"> Loading ... </h5>
                    </div>
                  ) : (
                    <div className="">
                      <Card className="col-lg-4 mt-1">
                        <CardBody className="p-4 h5 m-0">
                          No Product data available
                        </CardBody>
                      </Card>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={viewCommissioDetailModal}
          onHide={handleCommissionModal}
          size="xl"
        >
          <Modal.Header closeButton style={{ backgroundColor: "lightgrey" }}>
            <Modal.Title className="fw-bold">
              POSP Commission Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="bg-secondary3"
            style={{ padding: "1rem 1.5rem" }}
          >
            <CommissionApproval
              setViewCommssionDetailModal={setViewCommssionDetailModal}
              selectedUsers={selectedUsers}
              agentListRefetch={agentListRefetch}
              refetch={refetch}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={commissionCreationModal}
          onHide={() => setCommissionCrationModal(false)}
          size="xl"
        >
          <Modal.Header closeButton style={{ backgroundColor: "lightgrey" }}>
            <Modal.Title className="fw-bold">
              POSP Applications Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-2 ">
            <div className="d-flex flex-lg-row flex-column">
              <div className=" mx-2 col-lg-12 ">
                {/* <div className="col-lg-12   ">
                  {AgentDetailsError ? (
                    <Card className="userCard position-relative ">
                      <CardBody className="p-3 center_class">
                        <h5 className="m-0">
                          "No Data Avaiable for Selected Saathi"
                        </h5>
                      </CardBody>
                    </Card>
                  ) : AgentDetailLoading || AgentDetailsFetching ? (
                    <div>
                      <div className="wrapper2">
                        <div className="card-loader card-loader--tabs"></div>
                      </div>
                    </div>
                  ) : (
                    <div className=" d-flex mx-2  align-items-center">
                      <div className="card">
                        <div className="upper">
                          <img
                            src="https://i.imgur.com/Qtrsrk5.jpg"
                            className="img-fluid"
                          />
                        </div>

                        <div className="user text-center">
                          <div className="profile">
                            <img
                              src="https://imgs.search.brave.com/urF8FbrZXLObJsFKVlzVgKROpbHRvjT24j03KO9nOZI/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by91/c2VyLWZyb250LXNp/ZGUtd2l0aC13aGl0/ZS1iYWNrZ3JvdW5k/XzE4NzI5OS00MDAw/Ny5qcGc_c2l6ZT02/MjYmZXh0PWpwZw"
                              className="rounded-circle"
                              width="50"
                            />
                          </div>
                        </div>

                        <div className=" text-center ">
                          <h4 className="mb-0 text-success">
                            {AgentDetails?.data?.full_name
                              ?.charAt(0)
                              ?.toUpperCase() +
                              AgentDetails?.data?.full_name
                                ?.slice(1)
                                ?.toLowerCase()}
                          </h4>
                          <h5 className=" d-block mb-1 text-success">
                            {" "}
                            {AgentDetails?.data?.posp_id}
                          </h5>

                          <div className="d-flex justify-content-center align-items-center px-4 ">
                            <div className="d-flex mb-2 text-success">
                              <h5 className="mb-0">Commission % </h5>
                              <h5 className="mx-1">
                                {AgentDetails?.data?.commission_percent}
                              </h5>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center align-items-center ">
                            <div className="col-lg-10">
                              <hr className="m-0"></hr>
                            </div>
                          </div>

                          
                        </div>
                      </div>
                    </div>
                  )}
                </div> */}
                <CreateManualCommission
                  data={applicationList?.data}
                  refetch={applicationListRefetch}
                  isError={applicationListError}
                  isLoading={applicationListLoaing}
                  isFetching={applicationlistFetching}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default AssingCommission;
