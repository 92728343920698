import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Input } from "reactstrap";
import swal from "sweetalert";
import { useUpdateProductDatabyIdMutation } from "./CommissionApiSlice";

const EditProduct = ({ productData,refetch,ModalState }) => {
  const [data, setData] = useState("");
  const [ UpdateProductData] = useUpdateProductDatabyIdMutation();

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
   const sendData= {
        product_name: data.product_name,
        product_category: data.product_category,
        product_description:data.product_description,
        bank_name:data.bank_name,
        leads_commission_percent:data.leads_commission_percent,
        commission_percent: data.commission_percent,
    }

    UpdateProductData({ product_code: productData?.product_code, payload: sendData })
    .unwrap()
    .then((data) => {
      if (data?.status == 200) {
        swal("Product Data Updated Successfully", {
          buttons: false,
          icon: "success",
          title: "Data Updated ",
          timer: 1500,
        });
        setTimeout(() => {
          ModalState(false);
        }, 2000);
        refetch()
        
      } else {
        swal("Error", {
          buttons: false,
          icon: "warning",
          title: data.message,
          timer: 1500,
        });
      }
      
    })
    // .catch((e) => {
    //   swal("Error", {
    //     buttons: false,
    //     icon: "warning",
    //     title: data.message,
    //     timer: 3000,
    //   });

    // });
  };
  
  return (
    <div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row className="text-gray">
        <Col lg="4" className="mb-3">
            <label className="text-black mb-2">Bank Name</label>
            <Input
              type="text"
              name="bank_name"
              placeholder="Enter Domain"
              onChange={handleChange}
              disabled
              defaultValue={productData?.bank_name}
              className="rounded input_text2"
            />
          </Col>
          <Col lg="4" className="mb-3">
            <label className="text-black mb-2">Product Category</label>
            <Input
              type="text"
              name="product_category"
              disabled
              placeholder="Enter Product Category"
              onChange={handleChange}
              defaultValue={productData?.product_category}
              
              className="rounded input_text2"
            />
          </Col>
          <Col lg="4" className="mb-3">
            <label className="text-black mb-2">Product Code</label>
            <Input
              type="text"
              name="product_code"
              placeholder="Enter Product Code"
              onChange={handleChange}
              disabled
              defaultValue={productData?.product_code}
              className="rounded input_text2"
            />
          </Col>
          <Col lg="4" className="mb-3">
            <label className="text-black mb-2">Product Name</label>
            <Input
              type="text"
              name="product_name"
              placeholder="Enter Product name"
              onChange={handleChange}
              defaultValue={productData?.product_name}
              disabled
              className="rounded input_text2"
            />
          </Col>
         
          <Col lg="4" className="mb-3">
            <label className="text-black mb-2">Product Description</label>
            <Input
              type="text"
              name="product_description"
              placeholder="Enter Product Description"
              onChange={handleChange}
              disabled
              defaultValue={productData?.product_description}

              className="rounded input_text2"
            />
          </Col>
        
          
          {/* <Col lg="4" className="mb-3">
            <label className="text-black mb-2">Lead Commission %</label>
            <Input
              type="text"
              name="leads_commission_percent"
              placeholder="Enter amount in %"
              onChange={handleChange}
              defaultValue={productData?.leads_commission_percent}
              className="rounded input_text2"
            />
          </Col> */}
          <Col lg="4" className="mb-3">
            <label className="text-black mb-2">Saathi Commission %</label>
            <Input
              type="text"
              name="commission_percent"
              placeholder="Enter Product name"
              onChange={handleChange}
              defaultValue={productData?.commission_percent}
              className="rounded input_text2"
            />
          </Col>

          <div className=" text-center m-auto  mt-3">
            <Button type="submit" className="btn btn-success px-4" size="sm">
              <i className="fas fa-save"></i> Submit
            </Button>
          </div>
        </Row>
      </form>
    </div>
  );
};

export default EditProduct;
