import React, { useEffect, useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Button, Card, Table } from "react-bootstrap";
import { MDBBadge } from "mdb-react-ui-kit";
import swal from "sweetalert";
import { CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import { selectCurrentRoles } from "features/auth/authSlice";
import creditImg from "../../../assets/images/credit-card.png";
import total_img from "../../../assets/images/total_img.png";
import {
  useCreateLedgersMutation,
  useGetCommissionDetailsbyidQuery,
} from "./CommissionApiSlice";

const CommissionApproval = ({ selectedUsers, refetch, agentListRefetch }) => {
  const [pendingList, setPendingList] = useState([]);
  const [approvedList, setApprovedList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Approved");
  const RolesFromToken = useSelector(selectCurrentRoles);
  var Maker = RolesFromToken.groups.includes("Maker");
  const [CreateLedgers] = useCreateLedgersMutation();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const {
    data: CommissionDetail,
    isError: commitionDetailIsError,
    isLoading: commitionDetailisLoading,
    isFetching: commitionDetailisFetching,
    refetch: ComissionDetailRefetch,
  } = useGetCommissionDetailsbyidQuery(selectedUsers, {
    skip: !(selectedUsers.length > 0),
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (
      !commitionDetailisLoading &&
      !commitionDetailisFetching &&
      CommissionDetail
    ) {
      const pending = CommissionDetail?.data?.filter(
        (item) =>
          item.status === "Pending" ||
          item.status === "Raise Request" ||
          item.status === "Rejected"
      );
      const approved = CommissionDetail?.data?.filter(
        (item) => item.status === "Approved" || item.status === "Paid"
      );

      setPendingList(pending);
      setApprovedList(approved);
    }
  }, [CommissionDetail, commitionDetailisLoading, commitionDetailisFetching]);

  if (commitionDetailisLoading || commitionDetailisFetching) {
    return (
      <div>
        <h5>Loading...</h5>
      </div>
    );
  }

  const handleRowClick = (item) => {
    if (selectedRows.includes(item.application_id)) {
      setSelectedRows(selectedRows.filter((id) => id !== item.application_id));
    } else {
      setSelectedRows([...selectedRows, item.application_id]);
    }
  };

  const handleApprove = (e) => {
    e.preventDefault();
    const sendData = {
      application_ids: selectedRows,
      status: e.target.name,
    };

    CreateLedgers(sendData)
      .unwrap()
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          swal(
            e.target.name === "Raise Request"
              ? "Request Raised"
              : e.target.name === "Approved"
              ? "Approved Successfully"
              : e.target.name === "Reject"
              ? "Rejected"
              : "Paid Successfully",
            {
              buttons: false,
              icon: "success",
              title: "Data Updated ",
              timer: 1500,
            }
          );
          // setTimeout(() => {
          //   setViewCommssionDetailModal(false);
          // }, 2000);
          setSelectedRows([]);
          ComissionDetailRefetch();
          agentListRefetch();
          refetch();
        } else {
          swal("Error", {
            buttons: false,
            icon: "warning",
            title: data.message,
            timer: 1500,
          });
        }
      })
      .catch((e) => {
        var errorMessage = e.data.error.map((item) => item).join("\n");
        swal(errorMessage, {
          buttons: false,
          icon: "warning",
          title: "Something went Wrong",
          timer: 3000,
        });
      });
  };

  return (
    <div>
      <div className="row mt-2">
        {commitionDetailIsError ||
        commitionDetailisLoading ||
        commitionDetailisFetching ? (
          <div className="text-center p-2">
            <h5 className="text-black">No Commission Data Avaliable</h5>
          </div>
        ) : (
          <Card>
            <CardBody style={{ padding: "1.4rem 1.5rem" }}>
              <div className="fw-bold  d-flex justify-content-between">
                <div className="h5 m-0">
                  Agent Name :
                  <span className="steelblue ">
                    {" "}
                    {CommissionDetail?.data[0].name}
                  </span>
                </div>
                <div className="h5 m-0">
                  Agent ID :
                  <span className="steelblue  ">
                    {" "}
                    {CommissionDetail?.data[0].posp_id}
                  </span>
                </div>
              </div>
              <div className="fw-bold  d-flex justify-content-between mt-1">
                <div className="h5 m-0">
                  Total Commission{" "}
                  <img
                    src={total_img}
                    className="img-fluid"
                    style={{ height: "16px", width: "16px" }}
                  />{" "}
                  :
                  <span className="steelblue ">
                    <CurrencyRupeeIcon className="fs-6" />{" "}
                    {CommissionDetail?.total_commissions}
                  </span>
                </div>
                <div className="d-flex">
                  <div className="h5 m-0 mx-3">
                    Total Credited{" "}
                    <img
                      src={creditImg}
                      className="img-fluid"
                      style={{ height: "16px", width: "16px" }}
                    />{" "}
                    :
                    <span className="steelblue ">
                      <CurrencyRupeeIcon className="fs-6" />{" "}
                      {CommissionDetail?.total_credited}
                    </span>
                  </div>

                  <div className="h5 m-0 ">
                    Total Pending :
                    <span className="steelblue  ">
                      <CurrencyRupeeIcon className="f300" />{" "}
                      {CommissionDetail?.total_pending}
                    </span>
                  </div>
                </div>
              </div>

              <div className="wrapper col-lg-3 mt-3">
                <div className="commission_option col-lg-6">
                  <input
                    value="Approved"
                    name="radio_btn"
                    type="radio"
                    className="commission_input"
                    checked={selectedOption === "Approved"}
                    onChange={handleOptionChange}
                  />
                  <div className="radio_btn">
                    <span className="span_title">Approved / Paid</span>
                  </div>
                </div>
                <div className="commission_option col-lg-6">
                  <input
                    value="Pendings"
                    name="radio_btn"
                    type="radio"
                    className="commission_input"
                    checked={selectedOption === "Pendings"}
                    onChange={handleOptionChange}
                  />
                  <div className="radio_btn">
                    <span className="span_title">Pending</span>
                  </div>
                </div>
              </div>

              {selectedOption === "Pendings" ? (
                pendingList.length > 0 ? (
                  <Table striped className="mt-3 commission_table">
                    <thead>
                      <tr>
                        <th className="th-sm">Select</th>
                        <th className="th-sm">Application ID</th>
                        <th className="th-sm ">Product Name</th>

                        <th className="th-sm">State</th>
                        <th className="th-sm">District</th>
                        <th className="th-sm">Disbursed Amount</th>
                        <th className="th-sm">Commission</th>
                        <th className="th-sm">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendingList?.map((item) => (
                        // console.log(item)
                        <tr
                          key={item.application_id}
                          className={`mt-3 ${
                            selectedRows.includes(item.application_id)
                              ? "selected-row"
                              : ""
                          }`}
                        >
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(item.application_id)}
                              onChange={() => handleRowClick(item)}
                            />
                          </td>
                          <td>{item.application_id}</td>
                          <td>{item.product_name}</td>
                          <td>{item.state}</td>
                          <td>{item.district}</td>
                          <td>
                            {" "}
                            <CurrencyRupeeIcon
                              className="f300"
                              style={{ marginTop: "-3%" }}
                            />
                            {item.disbursed_amount}
                          </td>
                          <td>
                            <CurrencyRupeeIcon
                              className="f300"
                              style={{ marginTop: "-3%" }}
                            />
                            {item.saathi_commission_amount}
                          </td>
                          <td>
                            <MDBBadge
                              pill
                              style={{ padding: "6px" }}
                              className="font-size-10  px-2"
                              color={
                                item.status === "Pending"
                                  ? "warning"
                                  : item.status === "Paid" ||
                                    item.status === "Approved"
                                  ? "success"
                                  : item.status === "Raise Request"
                                  ? "primary"
                                  : item.status === "Rejected"
                                  ? "danger"
                                  : "secondary"
                              }
                            >
                              {item.status}
                            </MDBBadge>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="d-flex mt-3 mx-2">
                    {" "}
                    <h5>No Commission Data Available</h5>
                  </div>
                )
              ) : approvedList.length > 0 ? (
                <Table striped className="mt-3 commission_table">
                  <thead>
                    <tr>
                      <th className="th-sm">Select</th>
                      <th className="th-sm">Application ID</th>
                      <th className="th-sm">Product Name</th>
                      <th className="th-sm">State</th>
                      <th className="th-sm">District</th>
                      <th className="th-sm">Disbursed Amount</th>
                      <th className="th-sm">Commission</th>
                      <th className="th-sm">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvedList?.map((item) => (
                      <tr
                        key={item.application_id}
                        className={`mt-3 ${
                          selectedRows.includes(item.application_id)
                            ? "selected-row"
                            : ""
                        }`}
                      >
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(item.application_id)}
                            onChange={() => handleRowClick(item)}
                          />
                        </td>
                        <td>{item.application_id}</td>
                        <td>{item.product_name}</td>
                        <td>{item.state}</td>
                        <td>{item.district}</td>
                        <td>
                          {" "}
                          <CurrencyRupeeIcon
                            className="f300"
                            style={{ marginTop: "-3%" }}
                          />
                          {item.disbursed_amount}
                        </td>
                        <td>
                          <CurrencyRupeeIcon
                            className="f300"
                            style={{ marginTop: "-3%" }}
                          />
                          {item.saathi_commission_amount}
                        </td>
                        <td>
                          <MDBBadge
                            pill
                            style={{ padding: "6px" }}
                            className="font-size-10  px-2"
                            color={
                              item.status === "Pending"
                                ? "warning"
                                : item.status === "Paid" ||
                                  item.status === "Approved"
                                ? "success"
                                : item.status === "Raise Request"
                                ? "primary"
                                : item.status === "Rejected"
                                ? "danger"
                                : "secondary"
                            }
                          >
                            {item.status}
                          </MDBBadge>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="d-flex mt-3 mx-2">
                  {" "}
                  <h5>No Commission Data Available</h5>
                </div>
              )}

              <div className="d-flex justify-content-center align-items-center ">
                {Maker ? (
                  <div className="d-flex text-center">
                    {selectedOption === "Pendings" ? (
                     pendingList.length > 0 ? <Button
                        className="btn btn-warning cursor mt-2"
                        disabled={selectedRows.length < 1}
                        onClick={(e) => handleApprove(e)}
                        name="Raise Request"
                      >
                        Raise Request
                      </Button> : ""
                    ) : approvedList.length > 0 ? (
                      <Button
                        className="btn btn-success cursor mt-2"
                        disabled={selectedRows.length < 1}
                        onClick={(e) => handleApprove(e)}
                        name="Paid"
                      >
                        Paid
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                ) : selectedOption === "Approved" ? (
                  ""
                ) : pendingList.length > 0 ? (
                  <div className="d-flex text-center">
                    <Button
                      className="btn btn-success cursor mt-2"
                      disabled={selectedRows.length < 1}
                      onClick={(e) => handleApprove(e)}
                      name="Approved"
                    >
                      Approve
                    </Button>
                    <Button
                      className="btn btn-danger cursor mt-2"
                      disabled={selectedRows.length < 1}
                      onClick={(e) => handleApprove(e)}
                      name="Rejected"
                    >
                      Reject
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </CardBody>
          </Card>
        )}
      </div>
    </div>
  );
};

export default CommissionApproval;
