import React, { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { CardBody, Input } from "reactstrap";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import Pagination from "@mui/material/Pagination";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useGetLedgersReportQuery } from "./CommissionApiSlice";

const LedgersReport = () => {
  const [openFilter, setOpenFilter] = useState(false)
  const [startDate, setStartDate] = useState("");
  const [searchKey,setSearchKey]=useState("")

  const [pagination, setPagination] = useState({ page: 1, per_page: 10, totalPages: 1});
  const [ReportData, setReportData] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartEndDate = (value) => {
    if (value !== null && value.length > 0) {
      let constDateList = [];
      value.map((item, index) => {
        const inputDate = new Date(item);
        const formattedDate = moment(inputDate).format("YYYY-MM-DD");
        if (index === 0) {
          setStartDate(formattedDate);
          constDateList.push(formattedDate);
        }
        if (index === 1) {
          setEndDate(formattedDate);
          constDateList.push(formattedDate);
        }
      });
    }
  };
  const disabledDate = (date) => {
    return date && date > new Date();
  };



  const handleClean = () => {
    setStartDate("");
    setEndDate("");
    refetch();
  };


  const { data, isLoading,isFetching, refetch } = useGetLedgersReportQuery({
    startDate: startDate,
    endDate: endDate,
    page: pagination.page,
    per_page: pagination.per_page,
    query:searchKey
  });

  useEffect(() => {
    if (data && data.data) {
      setReportData(data.data);
      setPagination({
        ...pagination,
        per_page: data.pagination.per_page,
        page: data.pagination.page,
        totalPages: data.pagination.total_pages,
      });
    }
  }, [data]);

 

  const handleButtonClick=()=>{
    setOpenFilter(!openFilter)
 }
 const handleChangeSearch = (e) => {
  setSearchKey(e.target.value);
  refetch();
};

  return (
    <Card>
      <CardBody className="p-4">
      <div className="filters_container">
        <Button type="btn" className="filters_button fs-6 rounded "   onClick={handleButtonClick} style={{backgroundColor:"white",color:"#fd9444" , border:"1px solid #fd9444"}}>
           Select Filters {openFilter === true ?   <span className="mdi mdi-window-close fs-6"></span> : <span className="mdi mdi-menu-right fs-6"></span> }

        </Button>
        {openFilter && (
        <div className="filter_class mt-2">
        <div className="d-flex">
          <DateRangePicker
            className="col-lg-6 rounded"
            size="l"
            color="red"
            placeholder="Select Date Range"
            onChange={handleStartEndDate}
            shouldDisableDate={disabledDate}
            onClean={handleClean}
          />
          <div className="col-lg-5 mx-3">
            <Input
              type="text"
              required
              className="rounded"
              placeholder=" Search by Agent Name"
              onChange={(e)=>handleChangeSearch(e)}
              style={{ fontSize: "14px", height:"34px" }}
            >
             
            </Input>
          </div>
        </div>
        </div>
        )
        }
      </div>
       
        {isLoading || isFetching ? (
          <Skeleton count={10} height="20px" className="mt-2" />

        ) : (
          <div className="row d-flex justify-content-between flex-lg-row flex-column   mt-3">
            {ReportData &&
              ReportData.map((item, index) => (
                <Card style={{ borderLeft: "2px solid orange" }} className="ledgres_card" key={index}>
                  <div
                    className="indexStyle2 mt-2"
                    style={{ borderLeft: "1px solid orange" }}
                  >
                    {index + 1}  
                  </div>

                  <CardBody style={{ padding: "1rem 1.5rem" }}>
                    <div className="fw-bold  d-flex justify-content-between">
                      <div className="h5">
                        {" "}
                        Name : <span className="orange_text ">{item.agent_name}</span>
                      </div>
                      <div className="h5">
                         POSP ID:
                        <span className="orange_text  "> {item.posp_id}</span>
                      </div>
                    </div>

                    {/* <div className="fw-bold f300 d-flex justify-content-between mt-2">
                      <div className="h5">
                        Party :{" "}
                        <span className="orange_text">{item.party}</span>
                      </div>
                      <div className="h5">
                        Party Type :
                        <span className="orange_text "> {item.party_type}</span>
                      </div>
                    </div> */}

                    <Table striped responsive className="mt-2 commission_table">
                      <thead>
                        <tr>
                          <th className="th-sm">Application Id</th>
                          <th className="th-sm">Ledger Id</th>
                          <th className="th-sm">Debit</th>
                          <th className="th-sm">Credit</th>
                          <th className="th-sm">Posting Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{item.application_id}</td>
                          <td>{item.name}</td>
                          <td><CurrencyRupeeIcon className="fs-6 text-success" style={{marginTop:"-4%"}} />{item.debit}</td>
                          <td><CurrencyRupeeIcon className="fs-6 text-success"  style={{marginTop:"-4%"}}  />{item.credit}</td>
                          <td>{item.posting_date}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              ))}
            <div>
              <Pagination
                count={pagination.totalPages}
                
                onChange={(event, value) => {
                  setPagination({
                    ...pagination,
                    page: ((pagination.per_page * value) + 1) - pagination.per_page,
                    per_page: pagination.per_page
                  });
                }}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </div>
         
        )}
      </CardBody>
    </Card>
  );
};

export default LedgersReport;
