import Footer from 'shared/Footer';
import Navbar from 'shared/Navbar';
import Sidebar from 'shared/Sidebar';
import SettingsPanel from 'shared/SettingsPanel';
import { Outlet } from 'react-router-dom';
import CustomBreadCrumbs from 'components/CustomBreadCrumbs';


const Layout = () => {
    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <CustomBreadCrumbs />  
                    <div className="content-wrapper  ">
                        
                        {/* <BlankPage /> */}
                        <Outlet/>
                        <SettingsPanel />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Layout