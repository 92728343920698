// import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";

// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//       resources:{
//           en: {
//               translations: {
//                 "Overview dashboard": "Overview dashboard",
//               }
//           },
//           ar: {"Copyright": "Copyright Hindi"}
//       },
//       fallbackLng: "en",
//     debug: false,

//     // have a common namespace used around the full app
//     ns: ["translations"],
//     defaultNS: "translations",

//     keySeparator: false, // we use content as keys

//     interpolation: {
//       escapeValue: false
//     }
//   });

//   export default i18n;
// i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        seasonal_lulc: "Seasonal LULC",
        sowing_progress: "Sowing Progress",
        crop_health: "Crop Health",
        crop_acreage: "Crop Acreage",
        "crop-acerage": "crop-acerage",
        harvest_progress: "Harvest Progress",
        yield: "Yield",
        summary: "Summary",
        gt_dashboard: "GT Dashboard",
        Rabi: "Rabi",
        Kharif: "Kharif",
        "Select District": "Select District",
        "Select Block": "Select Block",
        "Select GP": "Select GP",
        Type: "Type",
        "Select Season": "Select Season",
        "Select Language": "Select Language",
        English: "English",
        Hindi: "Hindi",
        "Select Crop": "Select Crop",
        Paddy: "Paddy",
        Maize: "Maize",
        Jowar: "Jowar",
        Bajra: "Bajra",
        Groundnut: "Groundnut",
        Soybean: "Soybean",
        Others: "Others",
        Wheat: "Wheat",
        Mustard: "Mustard",
        Potato: "Potato",
        Pea: "Pea",
        Peas: "Peas",
        Others: "Others",
        Lentil: "Lentil",
        Gram: "Gram",
        Onion: "Onion",
        All: "All",
        "Crop Land Area (in Ha)": "Crop Land Area (in Ha)",
        "Waste Land Area (in Ha)": "Waste Land Area (in Ha)",
        "Forest Area (in Ha)": "Forest Area (in Ha)",
        "Plantation Area (in Ha)": "Plantation Area (in Ha)",
        "Built Up Area (in Ha)": "Built Up Area (in Ha)",
        "Water Bodies (in Ha)": "Water Bodies (in Ha)",
        "Fallow Area (in Ha)": "Fallow Area (in Ha)",
        "Other Area (in Ha)": "Other Area (in Ha)",
        "Total Sown Area (in Ha)": "Total Sown Area (in Ha)",
        "Paddy Harvested": "Paddy Harvested",
        "Paddy Standing": "Paddy Standing",
        "Maize Harvested": "Maize Harvested",
        "Maize Standing": "Maize Standing",
        "Jowar Harvested": "Jowar Harvested",
        "Jowar Standing": "Jowar Standing",
        "Bajra Harvested": "Bajra Harvested",
        "Bajra Standing": "Bajra Standing",
        "Pea Harvested": "Pea Harvested",
        "Pea Standing": "Pea Standing",
        "Soybean Harvested": "Soybean Harvested",
        "Soybean Standing": "Soybean Standing",
        Good: "Good",
        Average: "Average",
        Poor: "Poor",
        "Wheat Harvested": "Wheat Harvested",
        "Wheat Standing": "Wheat Standing",
        "Potato Standing": "Potato Standing",
        "Potato Harvested": "Potato Harvested",
        "Lentil Harvested": "Lentil Harvested",
        "Lentil Standing": "Lentil Standing",
        "Onion Harvested": "Onion Harvested",
        "Onion Standing": "Onion Standing",
        "Gram Harvested": "Gram Harvested",
        "Gram Standing": "Gram Standing",
        "Mustard Harvested": "Mustard Harvested",
        "Mustard Standing": "Mustard Standing",
        GroundNut: "मूंगफली ",
        Harvested: "Harvested",
        Standing: "Standing",
        first: "first",
        second: "second",
        First: "First",
        Second: "Second",
        Third: "Third",
        "Technology Based Command Center for Crop Acreage and Yield Monitoring":
          "Technology Based Command Center for Crop Acreage and Yield Monitoring",
        "Kharif Total Sown Area": "Kharif Total Sown Area",
        "Rabi Total Sown Area": "Rabi Total Sown Area",
        "First Harvested": "First Harvested",
        "Second Harvested": "Second Harvested",
        "Third Harvested": "Third Harvested",
        "First Standing": "First Standing",
        "Second Standing": "Second Standing",
        "Third Standing": "Third Standing",
        "Sown Area": "Sown Area",
        "Crop Health": "Crop Health",
        "Crop Harvest": "Crop Harvest",
        Acreage: "Acreage",
        "Acreage First": "Acreage First",
        "Acreage Second": "Acreage Second",
        "Select Estimate": "Select Estimate",
        "Technology Based Command Center for Crop Acreage and Yield Monitoring":
          "Technology Based Command Center for Crop Acreage and Yield Monitoring",
          "Survey No":"Survey No",
          District:"District",
          "Crop Name":"Crop Name",
          "Crop Variety":"Crop Variety",
          "Created By":"Created By",
          "Created Date":"Created Date",
          "Variety Name":"Variety Name",
          "View Detail":"View Detail",
           "Zaid":"Zaid"
      },
    },
    hi: {
      translation: {
        seasonal_lulc: "मौसमी LULC",
        sowing_progress: "बोआई की प्रगति",
        crop_health: "फसल स्वास्थ्य",
        crop_acreage: "फसल क्षेत्रफल",
        "crop-acerage": "फसल क्षेत्रफल",
        "Crop Acreage": "फसल क्षेत्रफल",
        harvest_progress: "कटाई की प्रगति",
        yield: "पैदावार",
        Yield: "पैदावार",
        summary: "सारांश",
        gt_dashboard: "जीटी डैशबोर्ड",
        Rabi: "रबी",
        Kharif: "खरीफ",
        "Select District": "जिला चुनें",
        "Select Block": "ब्लॉक चुनें",
        "Select GP": "ग्रामपंचायत चुनें",
        Type: "प्रकार",
        "Select Season": "सीजन चुनें",
        "Select Language": "भाषा चुने",
        English: "अंग्रेज़ी",
        Hindi: "हिंदी",
        "Select Crop": "फसल चुने",
        Paddy: "धान",
        Maize: "मक्का",
        Jowar: "ज्वार",
        Bajra: "बाजरा",
        Groundnut: "मूंगफली",
        Soybean: "सोयाबीन",
        Others: "अन्य",
        Wheat: "गेहूं",
        Mustard: "सरसों",
        Potato: "आलू",
        Pea: "मटर",
        Lentil: "मसूर",
        Gram: "चना",
        Onion: "प्याज",
        All: "सभी",
        "Crop Land Area (in Ha)": "फसल भूमि क्षेत्र (हेक्टेयर में)",
        "Waste Land Area (in Ha)": "बंजर भूमि क्षेत्र (हेक्टेयर में)",
        "Forest Area (in Ha)": "वन क्षेत्र (हेक्टेयर में)",
        "Plantation Area (in Ha)": "वृक्षारोपण क्षेत्र (हेक्टेयर में)",
        "Built Up Area (in Ha)": "निर्मित क्षेत्र (हेक्टेयर में)",
        "Water Bodies (in Ha)": "जल निकाय (हेक्टेयर में)",
        "Fallow Area (in Ha)": "परती क्षेत्र (हेक्टेयर में)",
        "Other Area (in Ha)": "अन्य क्षेत्र (हेक्टेयर में)",
        "Total Sown Area (in Ha)": "कुल बोया गया क्षेत्र (हेक्टेयर में)",
        "Paddy Harvested": "धान Harvested",
        "Paddy Standing": "धान Standing",
        "Maize Harvested": "मक्का Harvested",
        "Maize Standing": "मक्का Standing",
        "Jowar Harvested": "ज्वार Harvested",
        "Jowar Standing": "ज्वार Standing",
        "Bajra Harvested": "बाजरे Harvested",
        "Bajra Standing": "बाजरा Standing",
        "Pea Harvested": "मटर Harvested",
        "Pea Standing": "मटर Standing",
        "Soybean Harvested": "सोयाबीन Harvested",
        "Soybean Standing": "सोयाबीन Standing",
        Good: "अच्छा",
        Average: "औसत",
        Poor: "खराब",
        "Wheat Harvested": "गेहूं Harvested",
        "Wheat Standing": "गेहूं Standing",
        "Potato Standing": "आलू Standing",
        "Potato Harvested": "आलू Harvested",
        "Lentil Harvested": "मसूर फसल",
        "Lentil Standing": "मसूर Standing",
        "Onion Harvested": "प्याज Harvested",
        "Onion Standing": "प्याज Standing",
        "Gram Harvested": "चने Harvested",
        "Gram Standing": "चने Standing",
        "Mustard Harvested": "सरसों Harvested",
        "Mustard Standing": "सरसों Standing",
        "Peas Standing": "मटर Standing",
        "Peas Harvested": "मटर Harvested",
        Others: "अन्य",
        "Others Standing": "अन्य Standing",
        "Others Harvested": "अन्य Harvested",
        "GroundNut Harvested": "मूंगफली Harvested",
        "GroundNut Standing": "मूंगफली Standing",
        Standing: "फ़सल खडी है",
        Harvested: "फ़सल कटाई",
        first: "प्रथम",
        second: "द्वितीय",
        First: "प्रथम",
        Second: "द्वितीय",
        Third: "तीसरा",
        "First Estimated": "प्रथम अनुमानित",
        "Second Estimated": "द्वितीय अनुमानित",
        "in Ha": "हेक्टेयर में",
        "Technology Based Command Center for Crop Acreage and Yield Monitoring":
          "फसल क्षेत्रफल और उपज की निगरानी के लिए प्रौद्योगिकी आधारित कमांड सेंटर",
        "Kharif Total Sown Area": "खरीफ कुल बोया गया क्षेत्र",
        "Rabi Total Sown Area": "रबी कुल बोया गया क्षेत्र",
        "First Harvested": "प्रथम कटाई",
        "Second Harvested": "द्वितीय कटाई",
        "Third Harvested": "तीसरा कटाई",
        "First Standing": "प्रथम खडी फसल",
        "Second Standing": "द्वितीय खडी फसल",
        "Third Standing": "तीसरा खडी फसल",
        "Sown Area": "बोया गया क्षेत्र",
        "Crop Health": "फसल स्वास्थ्य",
        "Crop Harvest": "फसल कटाई",
        Acreage: "एकड़",
        "Acreage Second": "एकड़ प्रथम",
        "Acreage First": "एकड़ द्वितीय",
        "Select Estimate": "अनुमान चुनें",
        "Survey No":"सर्वेक्षण संख्या",
        "District":"जिला",
        "Crop Name":"फसल का नाम",
        "Crop Variety":"फसल की विविधता",
        "Created By":"के द्वारा बनाई गई",
        "Created Date":"सृजित दिनांक",
        "Variety Name":"किस्म का नाम",
        "View Detail":"विस्तार से देखें",
        "Zaid":"ज़ैद"



      },
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
