import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeCard: "Dashboard",
  activeCardSurvey:localStorage.getItem('activeCardSurvey') || "Upload/Survey Creation",
  activeSession:"0"
};

const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
     setActiveCard: (state, action) => {
      state.activeCard = action.payload;
    },
    resetActiveCard: (state) => {
        state.activeCard = "Dashboard"; // Reset activeCard to initial state
      },
      setActiveCardSurvey: (state, action) => {
        state.activeCardSurvey = action.payload;
        localStorage.setItem('activeCardSurvey', action.payload); // Save to localStorage

      },
      resetActiveCardSurvey: (state) => {
          state.activeCardSurvey = "Upload/Survey Creation"; // Reset activeCard to initial state
          localStorage.removeItem("activeCardSurvey");

        },
        setActiveSession: (state, action) => {
          state.activeSession = action.payload;
        },
        resetActiveSession: (state) => {
            state.activeSession = "0"; // Reset activeCard to initial state
          },
  },
});

export const { setActiveCard,resetActiveCard ,setActiveCardSurvey,resetActiveCardSurvey,setActiveSession,resetActiveSession} = cardSlice.actions;
export default cardSlice.reducer;