import { Trans } from 'react-i18next';

const Footer = () => {
  return (
    <footer className="footer">
        <div className="container-fluid">
            <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block"><Trans>Copyright</Trans> © <a href="https://agrani.io/" target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}><Trans>Agrani</Trans> </a>2024</span>
                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"><Trans>Made with </Trans><i className="mdi mdi-heart menu-icon"></i> by <a href="https://agrani.io/" target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}><Trans>Agrani</Trans> </a></span>
            </div>
        </div>
    </footer>
  )
}

export default Footer