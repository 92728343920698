import React, { useState, useEffect } from 'react'
import CustomSearchBar from "components/CustomSearchBar";
import CustomTable from "components/CustomTable";
import { Col, Row } from "react-bootstrap";
import { CardBody } from "reactstrap";
import { useGetAggregationListQuery } from 'features/E-Mandi/EMandiApiSlice';

const AggregationBuyer = ({ activeKey }) => {
    const [searchKey, setSearchKey] = useState("")
    const [users, setUsers] = useState([]);
    const [pagination, setPagination] = useState({ page: 1, per_page: 10 });
    const { data: data, isLoading, refetch } = useGetAggregationListQuery({ activeKey });
    useEffect(() => {
        const dataFromApi = data?.data ? data?.data : [];
        const paginationFromApi = data?.pagination?.page
            ? { page: data?.pagination?.page, pageCount: data?.pagination?.total_pages }
            : pagination;

        setUsers(dataFromApi);
        setPagination(paginationFromApi);
    }, [data]);
    const columns = [

        { field: "user_name", headerName: "Name", width: 180 },
        { field: "user_mobile", headerName: "Phone No", width: 140 },
        { field: "role", headerName: "Role", width: 150 },
        {
            field: "created_at",
            headerName: "Creation Date",
            width: 120,
            renderCell: (cellvalues) => {
                return (
                    <div>{cellvalues?.row?.created_at ? cellvalues?.row?.created_at?.slice(0, 10) : ""}</div>
                );
            },
        }, 
        {
            field: "updated_at",
            headerName: "Updated Date",
            width: 150,
            renderCell: (cellvalues) => {
                return (
                    <div>{cellvalues?.row?.created_at ? cellvalues?.row?.updated_at?.slice(0, 10) : ""}</div>
                );
            },
        },  
        { field: "updated_by_name", headerName: "Updated By", width: 150 },
    ];
    const getRowId = (row) => row.customId;

    const tableData = {
        rows: users,
        columns: columns,
        paginationState: [pagination, setPagination],
        isLoading: isLoading,
        getRowId:getRowId
    };
    const handleChangeSearch = (message) => {
        setPagination({ page: 1, per_page: 10 })
        setSearchKey(message)
    }

    return (
        <div className='mt-5'>
            <CardBody className='mt-3' >
                <Row className="searchboxtable justify-content-end">
                    <Col md="2"  >
                        <CustomSearchBar sendSearchFunction={handleChangeSearch} className=" float-end" />
                    </Col>
                </Row>
                <div className='mt-3'>
                    <CustomTable
                        rows={tableData.rows}
                        columns={tableData.columns}
                        paginationState={tableData.paginationState}
                        loading={tableData.isLoading}
                    />
                </div>
            </CardBody>
        </div>
    )
}

export default AggregationBuyer
