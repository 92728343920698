import React from "react";
import ReactEcharts from "echarts-for-react";



const appConfig = {
  rotate: 90,
  align: 'left',
  verticalAlign: 'middle',
  position: 'insideBottom',
  distance: 15
};

const labelOption = {
  show: true,
  position: appConfig.position,
  distance: appConfig.distance,
  align: appConfig.align,
  verticalAlign: appConfig.verticalAlign,
  rotate: appConfig.rotate,
  formatter: '{c}  {name|{a}}',
  fontSize: 15,
  rich: {
    name: {}
  }
};

const ProductWisechart = ({ data }) => {
  const products = data.products;

  // Prepare the dataset
  const datasetSource = [
    ['product', 'Total Credited', 'Total Pending'],
    ...Object.keys(products).map(productName => [
      productName,
      // products[productName].total_commissions,
      products[productName].total_credited,
      products[productName].total_pending
    ])
  ];

  const option = {
    legend: {},
    tooltip: {},
    dataset: {
      source: datasetSource
    },
    xAxis: { 
      type: 'category',
      axisLabel: {
        rotate: 45 
      }
    },
  
    yAxis: {},
    series: [
      { 
        type: 'bar', 
        name: 'Total Credited',
        itemStyle: {
          color: '#91cc75' ,
          fontSize:10
        }
      },
      { 
        type: 'bar', 
        name: 'Total Pending',
        itemStyle: {
          color: '#fac858' 
        }
      }
    ]
  };

  return (
    <div>
      <ReactEcharts option={option} />
    </div>
  );
};

export default ProductWisechart;




