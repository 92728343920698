import { apiSlice } from "app/api/apiSlice";

export const CommissionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProductList: builder.query({
      query: () => `/erp-gateway/api/commission/products?start=1&limit=10`,
      // query: () => `/erp/api/erp/commission/products?start=1&limit=10`,
    }),
    getProductbyId: builder.query({
      query: (product_code) =>
        `/erp-gateway/api/commission/products/${product_code}`,
      // `/erp/api/erp/commission/products/${product_code}`,
    }),
    addproduct: builder.mutation({
      query: (payload) => ({
        // url: `/erp/api/erp/commission/products/`,
        url: `/erp-gateway/api/commission/products/`,
        method: `POST`,
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    updateProductDatabyId: builder.mutation({
      query: ({ product_code, payload }) => ({
        // url: `/erp/api/erp/commission/products/${product_code}`,
        url: `/erp-gateway/api/commission/products/${product_code}`,
        method: `PUT`,
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    addSaathiCommission: builder.mutation({
      query: (payload) => ({
        // url: `/erp/api/erp/commission/update-commission/`,
        url: `/erp-gateway/api/commission/update-commission/`,
        method: `POST`,
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getAgentsId: builder.query({
      // query: (posp_id) => `/erp/api/erp/commission/agents?agents=${posp_id}`,
      query: (posp_id) =>
        `/erp-gateway/api/commission/agents?agents=${posp_id}`,
    }),
    getAgentList: builder.query({
      query: ({ searchKey, pagination }) =>
        `/api/v1/onboarding/employees/POSP?q=${searchKey}&per_page=50&page=${pagination.page}`,
    }),
    getAgentDetails: builder.query({
      // query: (posp_id) => `/erp/api/erp/commission/agents/${posp_id}`,
      query: (posp_id) => `/erp-gateway/api/commission/agents/${posp_id}`,
    }),
    getCommissionDetailsbyid: builder.query({
      // query: (posp_id) => `/erp/api/erp/commission/commissions/posp/${posp_id}`,
      query: (posp_id) => `/erp-gateway/api/commission/commissions/posp/${posp_id}`,
    }),
    createLedgers: builder.mutation({
      query: (payload) => ({
        // url: `erp/api/erp/commission/commission-ledger`,
        url: `/erp-gateway/api/commission/commission-ledger`,
        method: `POST`,
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getLedgersReport: builder.query({
      //   query: ({ startDate, endDate, page, per_page ,query}) =>
      //     `/erp/api/erp/commission/reports?from_date=${startDate ||
      //       ""}&to_date=${endDate || ""}&page=${page}&per_page=${per_page}&query=${query}`,
      // }),
      query: ({ startDate, endDate, page, per_page, query }) =>
        `/erp-gateway/api/commission/reports?from_date=${startDate ||
          ""}&to_date=${endDate ||
          ""}&page=${page}&per_page=${per_page}&query=${query}`,
    }),
    getCommissionDashboardData: builder.query({
    //   query: ({ selectedState, selectedDistrict, selectedProductId }) =>
    //     `/erp/api/erp/commission/dashboard?state=${selectedState}&district=${selectedDistrict}&product=${selectedProductId}`,
    // }),
    query: ({ selectedState, selectedDistrict, selectedProductId }) =>
      `/erp-gateway/api/commission/dashboard?state=${selectedState}&district=${selectedDistrict}&product=${selectedProductId}`,
  }),
  }),
});

export const {
  useGetProductListQuery,
  useAddproductMutation,
  useGetProductbyIdQuery,
  useUpdateProductDatabyIdMutation,
  useAddSaathiCommissionMutation,
  useGetAgentsIdQuery,
  useGetAgentListQuery,
  useGetAgentDetailsQuery,
  useGetCommissionDetailsbyidQuery,
  useCreateLedgersMutation,
  useGetLedgersReportQuery,
  useGetCommissionDashboardDataQuery,
} = CommissionApiSlice;
