import React, { useEffect, useState } from "react";
import { CardBody, Col, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { API_URL } from "app/config";
import { selectCurrentToken, selectCurrentUser } from "features/auth/authSlice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import img1 from "../../../assets/images/default.jpg";
import pdfimg from "../../../assets/images/pdf-img.jpg";
import { Card } from "react-bootstrap";
import Lightbox from "react-awesome-lightbox";
import Swal from "sweetalert2";

const SaathiAgreement = ({ saathiDetails }) => {
  var applicaintId = localStorage.getItem("applicant_id");
  const [isEffects, setisEffects] = useState(false);
  const id = useParams();
  const currentUser = useSelector(selectCurrentUser);
  const user_token = useSelector(selectCurrentToken);
  const { handleSubmit, reset } = useForm();
  const [loader, setLoader] = useState(false);

  // console.log(saathiDetails?.data?.is_agreed, "jijijiji")
  const [checked, setChecked] = useState(saathiDetails?.data?.is_agreed || false);
  const [downloadUrl, setdownloadUrl] = useState("");

  const [showPdf, setPdf] = useState();

  const [Saathipdf, setSaathipdf] = useState(false);
  const [genratedurl, setgenratedurl] = useState(false);
  const [showFinalimage, setShowFinalImage] = useState({});
  const [Verify, setVerify] = useState();
  function handleClick() {
    const id = localStorage.getItem("applicant_id");
    localStorage.clear(id);
  }


  const onSubmit = () => {
    setLoader(true);
    var formData = new FormData();
    formData.append("is_agreed", checked);
    formData.append("created_by", currentUser.sub);
    formData.append("created_by_name", currentUser.name);

    fetch(`${API_URL}/empanelment/api/preview/${applicaintId || id.id}`, {
      method: "PUT",
      body: formData,
      headers: { Authorization: `Bearer ${user_token}` },
    }).then((result) => {
      console.log(result, "chplpl");
      if (result.status === 200) {
        result
          .json()
          .then((response) => {
            swal({
              icon: "success",
              title:"Thank You, Your Application is Saved Successfully, Download Your form and sign it and send it to: 1601, 16th Floor, World Trade Tower, Plot No. C-001, Sector 16, Noida, UP - 201301 . For Any Quary - +91 6390640749 info@leadsconnect.in",
              button: true,
            });
            setVerify(true);
            setLoader(false);
            setdownloadUrl(response?.data?.empanelment_form_url);
          })
          .catch((err) => console.log(err));
      } else {
        result
          .json()
          .then((response) => {
            swal("...Ooops", {
              buttons: false,
              icon: "warning",
              title: response.message,
              timer: 2500,
            });
            setLoader(false);
          })
          .catch((err) => console.log(err));
      }
    });
  };
  // console.log(downloadUrl ,"url")
  
useEffect(()=>{
  setChecked(saathiDetails?.data?.is_agreed)
},[saathiDetails?.data?.is_agreed])

  return (
    <React.Fragment>
      <Col lg="12">
        <div className="col-lg-12 m-auto pb-2">
          <form>
            <div className="row mt-6 d-flex justify-content-between">
              <div className="col-lg-3 col-md-6  col-12 form-check text_box mx-5 d-flex justify-content-center align-items-center ">
                <div className="">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="ExperienceinInsurance"
                    onChange={() => setChecked(!checked)}
                    // defaultChecked={ saathiDetails?.data?.is_agreed}
                    // checked={checked}
                    checked={true}
                    // disabled={Verify === true ? true : false}
                  />
                  <label className="form-check-label m-0 ms-3" htmlFor="Yes">
                    I read and accept the Terms and Conditions
                  </label>
                  <br />
                  <a
                    className="m-0 ms-2"
                    href="http://saathi.agrani.io.s3-website.ap-south-1.amazonaws.com/static/media/Terms-and-conditions.pdf"
                    target="_blank"
                  >
                    Click here to view Terms and Conditions
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12 d-flex justify-content-center align-items-center ">
                <button
                  type="submit"
                  className="btn btn-success primary p-3"
                  onClick={handleSubmit(onSubmit)}
                  // disabled={checked == false ? true : false}
                >
                  <span class="mdi mdi-content-save "></span> Submit Application
                  &nbsp;
                  {loader ? (
                    <Spinner animation="border" size="sm"></Spinner>
                  ) : (
                    ""
                  )}
                </button>
                {Verify == true ? (
                  <>
                    <a
                      className="btn btn-primary text-white p-3 ms-3"
                      href={downloadUrl}
                      onClick={handleClick}
                    >
                      Download Form
                    </a>
                  </>
                ) : null}
                {isEffects ? (
                  <Lightbox
                    image={showFinalimage}
                    onClose={() => {
                      setisEffects(!isEffects);
                    }}

                    // imageCaption={"Project " + parseFloat(  + imagesMain.media + 1)}
                  />
                ) : null}
              </div>
            </div>
          </form>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default SaathiAgreement;
