import { useCreateCommissionMutation } from "features/banking/BankingApiSlice";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Tooltip from "@mui/material/Tooltip";
import "react-loading-skeleton/dist/skeleton.css";
import swal from "sweetalert";

const CreateManualCommission = ({ data, refetch, isError, isLoading ,isFetching }) => {
  const [selectedId, setSelectedId] = useState("");

  const [CreateCommission] = useCreateCommissionMutation();
 
//   const handleRowClick = (posp_id) => {
//     setSelectedId(posp_id);
//   };

  const handleRowClick = (id) => {
    // e.preventDefault();
    const sendData = {
      application_id: id,
      
    };

    CreateCommission(sendData)
      .unwrap()
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          swal("Data Updated", {
            buttons: false,
            icon: "success",
            title: "Commission Created Successfully",
            timer: 1500,
          });
          refetch();
          
        } else {
          swal("Error", {
            buttons: false,
            icon: "warning",
            title: data.message,
            timer: 1500,
          });
         
        }
      })
      .catch((e) => {
        // swal(e?.data?.error?.posp_ids[0], {
        //   buttons: false,
        //   icon: "warning",
        //   title: "Error",
        //   timer: 3000,
        // });
      });
  };



  return (
    <div>
      {!isError ? (
        <Table striped={!isLoading } className="mt-3 commission_table">
          <thead>
            <tr>
              <th className="th-sm">Application ID</th>
              <th className="th-sm">Loan Category</th>
              <th className="th-sm">
                Loan Amount <br></br>Request
              </th>
              <th className="th-sm">
                Loan Amount <br></br> Sanctioned
              </th>
              <th className="th-sm">
                Loan Amount <br></br> Disbursed
              </th>
              <th className="th-sm">Application Status</th>
              <th className="th-sm">Create Commission</th>
            </tr>
          </thead>

          <tbody>
            {isLoading || isFetching
              ? Array.from({ length: 5 }).map((_, index) => (
                  <tr key={index}>
                    <td colSpan="8">
                      <Skeleton height={20} />
                    </td>
                  </tr>
                ))
              : data?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.application_id}</td>
                    <td>{item.loan_category}</td>
                    <td>{item.loan_amount_requested}</td>
                    <td>{item.loan_amount_sanctioned}</td>
                    <td>{item.loan_amount_disbursed}</td>
                    <td>{item.application_status}</td>

                    <td>
                     {item?.is_commission_created === false ?
                      <Tooltip title="Create Commission"> <i
                        className="mdi mdi-database-eye text-orange fs-5 cursor"
                        name="view"
                        onClick={() => handleRowClick(item.application_id)}
                      /> </Tooltip>
                      : "Created" }
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      ) : (
        <div className="text-center mt-2 mb-2">
          <h5>No data available</h5>
        </div>
      )}
    </div>
  );
};

export default CreateManualCommission;
