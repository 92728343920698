import React, { useEffect, useState } from "react";
import { useGetCommissionDashboardDataQuery, useGetProductListQuery } from "./CommissionApiSlice";
import { Card, CardBody, CardHeader, Input } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import noData from "../../../assets/images/noData.png";
import ReactEcharts from "echarts-for-react";
import ProductWisechart from "./ProductWiseChart";
import AgentWiseChart from "./AgentWiseChart";
import { DateRangePicker } from "rsuite";
import { Button, Col } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import { useGetDistrictByStateQuery, useGetStatesQuery } from "services/locationApiSlice";
import AgentWisePendingData from "./AgentWisePendingData";


const CommissonDashboard = () => {
  const [openFilter, setOpenFilter] = useState(false)
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict,setSelectedDistrict]=useState("")
  const [districtList,setDistrictList]=useState([])
  const [stateId, setStateId] = useState('');
  const [productList,setProductList]=useState([])
  const [selectedProduct,setSelectedProduct]=useState("")
  const [selectedProductId,setSelectedProductId]=useState("")

  const {
    data: dashboardData,
    isLoading,
    isError,
    refetch
  } = useGetCommissionDashboardDataQuery({selectedState,selectedDistrict,selectedProductId});

  const { data: getStates } = useGetStatesQuery();
  const { data: districtData } = useGetDistrictByStateQuery(stateId);
  const { data: productData }=useGetProductListQuery()
  

  const locationData = dashboardData?.data?.locations
  ? Object.entries(dashboardData.data.locations).map(([key, value]) => ({
      name: key,
      total_disbursed: value.total_disbursed, 
      total_credited: value.total_credited,
      total_pending: value.total_pending,
      value: value.total_disbursed
    }))
  : [];
 

    // const handleStartEndDate = (value) => {
    //   if (value !== null && value.length > 0) {
    //     let constDateList = [];
    //     value.map((item, index) => {
    //       const inputDate = new Date(item);
    //       const formattedDate = moment(inputDate).format("YYYY-MM-DD");
    //       if (index === 0) {
    //         setStartDate(formattedDate);
    //         constDateList.push(formattedDate);
    //       }
    //       if (index === 1) {
    //         setEndDate(formattedDate);
    //         constDateList.push(formattedDate);
    //       }
    //     });
    //   }
    // };

    const handleButtonClick=()=>{
        setOpenFilter(!openFilter)
     }
    

    // const disabledDate = (date) => {
    //     return date && date > new Date();
    //   };
    
      const handleClean = () => {
        setStartDate("");
        setEndDate("");
        refetch();
      };

      const option = {
        title: {
          text: "",
          subtext: "",
        },
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            const { name, data } = params;
            return `
              ${name} <br/>
              Total Credited: ₹ ${data.total_credited}<br/>
              Total Pending: ₹ ${data.total_pending}
            `;
          },
        },
        legend: {
          orient: "vertical",
          left: 20,
          top: 20,
          textStyle: {
            rich: {
              count: {
                align: "right",
                color: "#1976D2",
                fontWeight: 800,
              },
            },
          },
        },
        series: [
          {
            name: "Locations",
            type: "pie",
            radius: "70%",
            center: ["58%", "50%"],
            data: locationData.map((item) => ({
              name: item.name,
              value: item.total_credited + item.total_pending, 
              total_credited: item.total_credited,
              total_pending: item.total_pending
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
    }),
  };
  useEffect(() => {
    if (productData !== undefined) {
      setProductList(productData?.data);
    }
  }, [productData]);

  useEffect(() => {
    if (getStates !== undefined) {
      setStateList(getStates?.data?.results);
    }
  }, [getStates]);
  useEffect(() => {
    if (districtData !== undefined) {
      setDistrictList(districtData?.data?.results);
    }
  }, [districtData]);
  const handleStateOptions = () => {
    let responseAgentArray = [];
    stateList.map((state) => {
      responseAgentArray.push({
        value: state.state_code,
        label: state.state_name,

      });
    });
    return responseAgentArray;
  };
  
  const handleDistrictOptions = () => {
    let responseAgentArray = []
    districtList?.map((district) => {
      responseAgentArray.push({ value: district.district_code, label: district.district_name },)
    })
    return responseAgentArray
  }
  const productOption = () => {
    let productarray = []
    productList?.map((item) => {
        productarray.push({ value: item.product_code, label: item.product_name },)
    })
    return productarray
  }

  

  const onStateChange = (event) => {
    setSelectedState(event?.label );
    setStateId(event?.value)
    setSelectedDistrict("")

   
  };
  const onDistrictChange = (event) => {
    setSelectedDistrict(event?.label);
  };

  const onProductChange = (event) => {
    setSelectedProduct(event?.label);
    setSelectedProductId(event.value)
  };

  const handleClear = ()=>{
    setSelectedState("");
    setSelectedDistrict("")
    setSelectedProduct("");
    setSelectedProductId("")

  }




  return (
    <Card>
      <CardBody className="p-4">
      <div className="filters_container">
        <Button type="btn" className="filters_button fs-6 rounded mb-2" size="sm"  onClick={handleButtonClick} style={{backgroundColor:"white",color:"#fd9444" , border:"1px solid #fd9444"}}>
            Filters {openFilter === true ?   <span className="mdi mdi-window-close fs-6"></span> : <span className="mdi mdi-menu-right fs-6"></span> }

        </Button>
        {openFilter && (
        <div className="comission_dashboard_filter mt-2">
            <div className="cursor position-absolute text-secondary" onClick={()=>handleClear()} style={{top:"2px",right:"9px"}}>Reset Filter</div>

        <div className="d-flex">
            <div className="" style={{width:"32.5%"}}> 
              <label className="text-gray mb-1">Select State</label>
              <Select
                options={handleStateOptions()}
                onChange={onStateChange}
                styles={customStyles}
                placeholder={selectedState =="" ? "Select State": selectedState  } 
                value={selectedState == "" ? "Select State": selectedState }
              />
              
            </div>
            <div style={{width:"32.5%"}} className="mx-2"> 
              <label className="text-gray mb-1 ">Select District</label>
              <Select
                options={handleDistrictOptions()}
                onChange={onDistrictChange}
                styles={customStyles}
                placeholder={selectedDistrict =="" ? "Select District": selectedDistrict  } 
                value={selectedDistrict == "" ? "Select District": selectedDistrict }
              />
              
            </div>
            <div style={{width:"32.5%"}} className="mx-1"> 
              <label className="text-gray mb-1">Select Product</label>
              <Select
                options={productOption()}
                onChange={onProductChange}
                styles={customStyles}
                placeholder={selectedProduct =="" ? "Select Product": selectedProduct  } 
                value={selectedProduct == "" ? "Select product": selectedProduct}
              />
              
            </div>


          {/* <DateRangePicker
            className=" rounded"
            size="l"
            color="red"
            placeholder="Select Date Range"
            onChange={handleStartEndDate}
            shouldDisableDate={disabledDate}
            onClean={handleClean}
          /> */}
        </div>
        </div>
        )
        }
      </div>

        <div className="row
        ">
          <div className="col-lg-6 rounded ">
            <Card>
            <CardHeader className="h5 border-0" style={{background:"rgb(137 207 240 / 43%)" , color:"rgb(2 45 78)" , padding:"10px"}}>Location-wise Data</CardHeader>
              <CardBody className="p-2">
                {isLoading  ? (
                  <Skeleton count={8} height="25px" />
                ) : isError ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={noData} />
                  </div>
                ) : (
                  <ReactEcharts option={option} />
                )}
              </CardBody>
            </Card>
          </div>

          <div className="col-lg-6 rounded">
            <Card>
            <CardHeader className="h5 border-0" style={{background:"rgb(137 207 240 / 43%)" , color:"rgb(2 45 78)" , padding:"10px"}}>Product-wise Data</CardHeader>

              <CardBody className="p-3">
                {isLoading ? (
                  <Skeleton count={8} height="25px" />
                ) : isError ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={noData} />
                  </div>
                ) : (
                  <ProductWisechart data={dashboardData?.data} />
                )}
              </CardBody>
            </Card>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <Card>
              <CardHeader className="h5 border-0" style={{background:"rgb(137 207 240 / 43%)" , color:"rgb(2 45 78)" , padding:"10px"}}>Agent-wise Credited Data</CardHeader>
              <CardBody className="p-3">
                {isLoading ? (
                  <Skeleton count={8} height="25px" />
                ) : isError ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={noData} />
                  </div>
                ) : (
                  <AgentWiseChart data={dashboardData?.data} />
                )}
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-6">
            <Card>
              <CardHeader className="h5 border-0" style={{background:"rgb(137 207 240 / 43%)" , color:"rgb(2 45 78)" , padding:"10px"}}>Agent-Wise Pending Data</CardHeader>
              <CardBody className="p-3">
                {isLoading ? (
                  <Skeleton count={8} height="25px" />
                ) : isError ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={noData} />
                  </div>
                ) : (
                  <AgentWisePendingData data={dashboardData?.data} />
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default CommissonDashboard;
