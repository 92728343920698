import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Input } from "reactstrap";
import swal from "sweetalert";
import { useAddproductMutation } from "./CommissionApiSlice";

const AddProduct = ({refetch,ModalState,cardName }) => {
  const [data, setData] = useState("");
  const [ AddProductData] =   useAddproductMutation();

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
   const sendData= {
        product_name: cardName === "card_1" ? "State Bank of India" : cardName === "card_2" ? "Bank Of Baroda" : "HDFC",
        product_category: data.product_category,
        product_description:data.product_description,
        product_code:data.product_code,
        commission_domain:"Banking",
        bank_name:data.bank_name,
        leads_commission_percent:data.leads_commission_percent,
        commission_percent: data.commission_percent,
    }

    AddProductData(sendData)
    .unwrap()
    .then((data) => {
      if (data?.status == 201) {
        swal("Product Added Successfully", {
          buttons: false,
          icon: "success",
          title: "Data Updated ",
          timer: 1500,
        });
        ModalState(false)
        refetch()
        
      } else {
        swal("Error", {
          buttons: false,
          icon: "warning",
          title: data.message,
          timer: 1500,
        });
      }
      
    })
    // .catch((e) => {
    //   swal("Error", {
    //     buttons: false,
    //     icon: "warning",
    //     title: data.message,
    //     timer: 3000,
    //   });

    // });
  };
  return (
    <div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row className="text-gray">
        <Col lg="4" className="mb-3">
            <label className="text-black mb-2">Bank Name</label>
            <Input
              type="text"
              name="bank_name"
              placeholder="Enter Bank Name"
              onChange={handleChange}
              className="rounded input_text2"
              disabled
              value={cardName === "card_1" ? "State Bank of India" : cardName === "card_2" ? "Bank Of Baroda" : "HDFC"}
            />
          </Col>
          <Col lg="4" className="mb-3">
            <label className="text-black mb-2">Product Category</label>
            <Input
              type="text"
              name="product_category"
              placeholder="Enter Product Category"
              onChange={handleChange}
              className="rounded input_text2"
            />
          </Col>
          <Col lg="4" className="mb-3">
            <label className="text-black mb-2">Product Code</label>
            <Input
              type="text"
              name="product_code"
              placeholder="Enter Product Code"
              onChange={handleChange}
              className="rounded input_text2"
            />
          </Col>
          <Col lg="4" className="mb-3">
            <label className="text-black mb-2">Product Name</label>
            <Input
              type="text"
              name="product_name"
              placeholder="Enter Product name"
              onChange={handleChange}
              required
              className="rounded input_text2"
            />
          </Col>
          <Col lg="4" className="mb-3">
            <label className="text-black mb-2">Product Description</label>
            <Input
              type="text"
              name="product_description"
              placeholder="Enter Product Description"
              onChange={handleChange}
              className="rounded input_text2"
            />
          </Col>
          
          <Col lg="4" className="mb-3">
            <label className="text-black mb-2">Lead Commission %</label>
            <Input
              type="text"
              name="leads_commission_percent"
              placeholder="Enter amount in %"
              onChange={handleChange}
              className="rounded input_text2"
            />
          </Col>
          <Col lg="4" className="mb-3">
            <label className="text-black mb-2">Saathi Commission %</label>
            <Input
              type="text"
              name="commission_percent"
              placeholder="Enter Product name"
              onChange={handleChange}
              className="rounded input_text2"
            />
          </Col>

          <div className=" text-center m-auto  mt-3">
            <Button type="submit" className="btn btn-success px-5">
              <i className="fas fa-save"></i> Submit
            </Button>
          </div>
        </Row>
      </form>
    </div>
  );
};

export default AddProduct;
