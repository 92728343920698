import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "data/localstorage";

const initialState = {
    breadcrumbs: null,
    activeTab:1,
    activeCard:"Employees" 
}

const prevState = loadState()
// console.log("prev state", prevState)

const appSlice = createSlice({
    name: "app",
    initialState: {...initialState, ...prevState?.app},
    reducers: {
        setBreadcrumbs: (state, action) => { 
            state.breadcrumbs = action.payload 
        },
        setActiveTab: (state, action) => {                
            state.activeTab = action.payload
        },
        setActiveCard: (state, action) => {                
            state.activeCard = action.payload
        }

        
    }
})

export const { setBreadcrumbs,setActiveTab ,setActiveCard} = appSlice.actions

export default appSlice.reducer

export const selectBreadcrumbs = (state) => state.app.breadcrumbs


