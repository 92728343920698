import { apiSlice } from "app/api/apiSlice"
import { store } from 'app/store'
const state = store.getState()

export const dropdownApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        allDropDownData: builder.query({
            query: () => `/api/v1/master/dropdowns`
        }),

})
})

export const { useAllDropDownDataQuery} = dropdownApiSlice 