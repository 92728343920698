import React, { useEffect, useState } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AssingCommission from "./AssignCommission";
import ProductMaster from "./ProductMaster";
import LedgersReport from "./LedgersReport";
import CommissonDashboard from "./CommissonDashboard";
import CustomTabs from "components/CustomTabs";
const Commission = () => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    var x = JSON.parse(localStorage.getItem("activeBankingTab"));
    setValue(x);
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const tabs=[
    {
    tabName:"Banking",
    route:"/banking",
    component:"Banking",
    icon:<AccountBalanceIcon/>
},
{
    tabName:"Analytics",
    route:"/banking/analytics",
    component:"Analytics",
    icon:<FormatListBulletedIcon/>

},{
    tabName:"Commission",
    route:"/banking/commission",
    component:"Commission",
    icon:<AnalyticsIcon/>

},
]
  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("activeBankingTab", JSON.stringify(newValue));
  };
  return (
    <div style={{ marginTop: "-2%" }}>
        <CustomTabs tabs={tabs} activeTab="Commission"/>
      <div className="d-flex justify-content-between mx-3">
        <Tabs value={value} onChange={handleChange} textColor="primary">
          <Tab className="" label="PRODUCTS" iconPosition="start" />
          <Tab label="AGENTS" iconPosition="start" />
          <Tab label="LEDGERS REPORT" iconPosition="start" />
          <Tab label="DASHBOARD" iconPosition="start" />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <div className="mt-1">
          <ProductMaster />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AssingCommission />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="mt-1">
          <LedgersReport />
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className="mt-1">
          <CommissonDashboard />
        </div>
      </TabPanel>
    </div>
  );
};

export default Commission;
