import { apiSlice } from "app/api/apiSlice";

export const SaathiApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSathi: builder.query({
      query: ({ pagination,searchKey }) =>
        `/empanelment/api/user-list?limit=${pagination.limit}&offset=${pagination.offset}&page=${pagination.currentPage}&search=${searchKey}`,
    }),

    getSathiDetailsbyId: builder.query({
      query: (id) => `/empanelment/api/user-detail/${id}`,
    }),
    SaathiAprrovalbyId: builder.mutation({
      query: ({ ApplicantId, approval }) => ({
        url: `/empanelment/api/empanelment/action/${ApplicantId}`,
        method: `PATCH`,
        body: approval,
      }),
    }),
    getSathiAgreementForm: builder.query({
      query: (id) => `/empanelment/api/empanelment-form/download/${id}`,
    }),

    UploadAgreementForm: builder.mutation({
      query: ({ ApplicantId, formData }) => ({
        url: `/empanelment/api/empanelment-form/upload/${ApplicantId}`,
        method: `PATCH`,
        body: formData,
      }),
    }),
    regenerateSaathiForm: builder.query({
      query: (id) => `/empanelment/api/re-generate-empanelment-form/${id}`,
    }),
   
  }),
});

export const {
  useGetAllSathiQuery,
  useGetSathiDetailsbyIdQuery,
  useSaathiAprrovalbyIdMutation,
  useGetSathiAgreementFormQuery,
  useUploadAgreementFormMutation,
  useRegenerateSaathiFormQuery,

} = SaathiApiSlice;
