import React from "react";
import ReactEcharts from "echarts-for-react";

const AgentWiseChart = ({ data }) => {
  const agentData = data?.agents || [];
  
  const datasetSource = [
    ["agent", "Total Commission", "Total Credited"],
    ...agentData.map(item => [
      item.agent_name,
      item.total_commissions,
      item.total_credited,
    ]),
  ];

  const option = {
    dataset: {
      source: datasetSource,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: (params) => {
        const data = params[0].data;
        return `
          <div>
            <h6>${data[0]}</h6>
            <h6>Total Commissions: <span style="color: #FFA500;">₹ ${data[1]}</span></h6>
            <h6>Credited: <span style="color: #32CD32;">₹ ${data[2]}</span></h6>
          </div>
        `;
      },
    },
    grid: {
      top: "4%",
      left: "2%",
      right: "8%",
      bottom: "8%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      name: "Amount",
      nameLocation: "middle",
      nameTextStyle: {
        padding: 20,
      },
      axisLabel: {
        formatter: '₹ {value}',
      },
    },
    yAxis: {
      type: "category",
      name: "Agent",
    },
    series: [
      {
        type: "bar",
        name: "Total Credited",
        encode: {
          x: "Total Credited",
          y: "agent",
        },
        itemStyle: {
          color: "#5470c6",
        },
        label: {
          show: true,
          position: "right",
        },
      },
    ],
  };

  return (
    <div>
      <ReactEcharts option={option} />
    </div>
  );
};

export default AgentWiseChart;
