import { apiSlice } from "app/api/apiSlice"


export const BankBranchesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getIfsc: builder.query({
            query: (ifsc) => `/api/v1/master/bank_branches?q=${ifsc}`
        }),
      
       

})
})

export const { useGetIfscQuery} = BankBranchesApiSlice 