import React, { useState,useEfect } from "react";
import { Col } from "react-bootstrap";
import { Card, CardBody } from "reactstrap"
import AggregationBuyer from "./common/AggregationBuyer";
import { useDispatch ,useSelector} from 'react-redux';

const Aggregation = () => {

  const [activeCard, setActiveCard] = useState("Buyer")
  const dispatch = useDispatch();
  

  return (
    <div>
      <div className="radio-inputs" style={{ width: "45%" }}>
        <label className="radio">
          <div onClick={() => setActiveCard("Buyer")}>
            <input type="radio" name="radio" defaultChecked  />
            <h4 className="name2 text-primary m-0">Buyer</h4>
          </div>
        </label>
        <label className="radio">
          <div onClick={() => setActiveCard("Seller")}>
            <input type="radio" name="radio" />
            <h4 className="name2  m-0">Seller</h4>
          </div>
        </label>
      </div>
      {activeCard === "Buyer" ?
        <div className="mt-4 p-2">
          <AggregationBuyer activeKey={activeCard} />
        </div>
        :
        <div className="mt-4 p-2">
        </div>

      }

    </div>
  );
};

export default Aggregation;
